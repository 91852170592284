import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormDialog, TagRow, HStack, Text, Table, Tab, TabList, TabPanel, Card } from '@arcault-ui/shared-components';
import { Button, ChatLog, Form, Icon, SkeletonLoader, Stack } from "@arcault-ui/shared-components";
import { assetsAPI } from '../../actions/assets';
import { liabilitiesAPI } from '../../actions/liabilities';
import { useSecrets } from './infoAssets/useSecrets';
import { useSettings } from '../../context/SettingsContext';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { usePlaidLink } from 'react-plaid-link';

import './Assets.css';
import SecretDialog from './infoAssets/SecretDialog';
import ResearchSection from './ResearchSection'
import Budgeting from './Budgeting'
export const assetFields = {
  assetFields: [
    {
      id: 'accountType',
      type: 'autocomplete',
      label: 'Account Type',
      suggestions: [
        'Checking',
        'Savings',
        'Brokerage',
        '401(k)',
        'IRA',
        'Roth IRA',
        'Credit Card',
        'Mortgage',
        '529 Plan',
        'UGMA',
        'Other',
      ],
      column: true,
      form: true,
    },
    {
      id: 'institution',
      type: 'autocomplete',
      label: 'Institution',
      suggestions: ['Chase', 'Fidelity', 'Vanguard', 'Bank of America', 'Other'],
      column: true,
      form: true,
    },
  ],
  commonFields: [
    {
      id: 'description',
      label: 'Description',
      column: true,
      form: true,
      rank: 1,
      fullWidth: true,
    },
    {
      id: 'accountNumber',
      label: 'Account Number',
      type: 'text',
      mask: '****1234',
      form: true,
      rank: 10,
    },
    {
      id: 'estimatedValue',
      label: 'Estimated Value',
      type: 'currency',
      column: true,
      form: true,
      align: 'right',
      rank: 20,
    },
    {
      id: 'tags',
      label: 'Tags',
      type: 'tags',
      column: true,
      form: true,
      rank: 50,
    },
  ],
  extraFields: {
    Ownership: [
      { id: 'owner', label: 'Owner', type: 'text', form: true },
      { id: 'isJoint', label: 'Joint Account?', type: 'checkbox', form: true },
      { id: 'beneficiaries', label: 'Beneficiaries', type: 'tags', form: true },
    ],
    SecureInfo: [
      { id: 'routingNumber', label: 'Routing Number', type: 'text', form: true },
      { id: 'notes', label: 'Private Notes', type: 'textarea', fullWidth: true, form: true },
    ],
    Linked: [
      { id: 'hasLogin', label: 'Linked Account?', type: 'checkbox', form: true },
      { id: 'linkedAccountId', label: 'Linked Account ID', type: 'text', form: true },
    ],
  },
};


export const assetFields2 = {
  assetFields: [
    { id: 'assetType', type: 'autocomplete', suggestions: ['House', 'Car', 'Boat', 'Collection', 'Other'], label: 'Asset Type',column: true, form: true},
  ],
  commonFields: [
    { id: 'description', label: 'Description', column: true, form: true, rank: 1, fullWidth: true},
    { id: 'tags', label: 'Tags', type: 'tags', column: true, form: true, rank: 50},
    { id: 'estimatedValue', label: 'Estimated Value',column: true, form: true, rank: 100, align: 'right'},
  ],
  extraFields: {
    Car: [
      { id: 'make', label: 'Make' },
      { id: 'model', label: 'Model' },
      { id: 'year', label: 'Year' }
    ],
    House: [
      { id: 'address', label: 'Address', fullWidth: true},
      { id: 'size', label: 'Size (sqft)' },
      { id: 'lot', label: 'Lot Size (acres)' },
      { id: 'bedrooms', label: 'Bedrooms' },
      { id: 'baths', label: 'Baths' },
    ],
    Collection: [
      { id: 'items', label: 'Items' }
    ],
    financial: [
      { id: 'accountNumber', label: 'Account Number' }
    ],
  }
}


export const handleDynamicFields = (formData, setFields, isAsset, isEdit) => {
  let newFields = [];
  if (isAsset) {
    newFields = [...newFields, ...assetFields.assetFields];
    if (formData?.assetType) newFields = [...newFields, ...assetFields.commonFields, ...assetFields.extraFields[formData?.assetType] || []];
  } else {
    newFields = [...newFields, ...assetFields.commonFields]
  }
  setFields(newFields);
}

const Assets = () => {
  
  const [fields, setFields] = useState([...assetFields.assetFields, ...assetFields.commonFields]);
  const [activeTab, setActiveTab] = useState('Assets');
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { settings } = useSettings();
  const axiosWithCredentials = useAxiosWithCredentials();

  const [allUpdates, setAllUpdates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [connected, setConnected] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [editingAsset, setEditingAsset] = useState(null)
const navigate = useNavigate();


  const isAssets = activeTab === 'Assets';


  const loadItems = async () => {
    console.log('loadItems');
    setIsLoading(true);
    const response = await (isAssets ? assetsAPI : liabilitiesAPI).get();
    const dataArray = response.data;
    setRows(dataArray || []);
    setIsLoading(false);
  };


  const {
    secret,
    viewingSecret,
    editingSecret,
    handleOpenSecret,
    handleCloseSecret,
    handleSecretChange,
    handleEditSecret,
    cancelEditSecret,
    saveSecret,
  } = useSecrets({ service: isAssets ? 'assets' : 'liabilities', saveSecretCallback: loadItems });

  useEffect(() => {
    loadItems();
  }, [activeTab]);



  const handleDelete = async (row) => {
    await (isAssets ? assetsAPI : liabilitiesAPI).delete(row);
    loadItems();
  };
const handleEdit = (row) => {
  setEditingAsset(row);
};
  const handleSave = async (formData) => {
    console.log('handleSave', formData);
    try {
      const response = await (isAssets ? assetsAPI : liabilitiesAPI).save(formData);
      
      if (response.status === 200 || response.status === 201) {
        loadItems();
      } else {
        console.error('Create failed');
      }
    } catch (error) {
      console.error('Error during Create', error);
    }
  };

  const handleFormChangeCallback = (formData, isEdit) => {
    handleDynamicFields(formData, setFields, isAssets, isEdit); // need fix
  }

  const totalRow = (id, rows) => {
    switch (id) {
      case "assetType":
        return `${rows.length} items`;
      case "estimatedValue":
        const totalValue = rows.reduce((acc, row) => {
          return acc + (parseInt(row?.estimatedValue?.replace(/[^0-9.-]+/g, ""), 10) || 0)
        }, 0);
        return totalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      default:
        return '';
    }
  };

  const columns = [...assetFields.assetFields, ...assetFields.commonFields].map((field) => ({
    id: field.id,
    label: field.label,
    align: field.align || 'left',
  }));

  // Fetch link token from the backend
  const fetchLinkToken = async (assetId) => {
    setLoading(true);
    try {
      setSelectedRowId(assetId)
      const response = await axiosWithCredentials.post('/plaid/api/create_link_token', { assetId });
      setLinkToken(response.data.link_token);
    } catch (error) {
      console.error("Error fetching Plaid link token:", error);
    }
    setLoading(false);
  };

  // Handle successful Plaid connection
  const onSuccess = async (publicToken) => {
    setLoading(true);
    try {
      const response = await axiosWithCredentials.post('/plaid/api/set_access_token', { public_token: publicToken, assetId: selectedRowId });
      
      if (response.data.access_token) {
        setConnected(true);
        // onConnectionChange(assetId, true);
      }
      setSelectedRowId(null)
    } catch (error) {
      console.error("Error setting access token:", error);
    }
    setLoading(false);
  };

  // Initialize Plaid Link at the top level (only when linkToken is available)
  const { open, ready } = usePlaidLink(
    linkToken
      ? {
          token: linkToken,
          onSuccess,
          onExit: () => setLoading(false),
        }
      : { token: '' } // Avoid passing undefined/null to `usePlaidLink`
  );

  // Handle opening Plaid Link UI
  const handleConnect = async (row) => {
    if (!linkToken) {
      await fetchLinkToken(row.id);
    }
  };
  // Handle opening Plaid Link UI
  const handleRefresh = async (row) => {
    try {
      const response = await axiosWithCredentials.post(`/plaid/api/sync/${row.id}`, { });
      
      console.log(response)
    } catch (error) {
      console.error("Error setting access token:", error);
    }
  };
  // Open Plaid UI once it's ready
  useEffect(() => {
    if (linkToken && ready) {
      open();
    }
  }, [linkToken, ready]);
  const actions = [
    { label: 'edit', iconName: 'edit', onClick: handleEdit },
    { label: 'delete', iconName: 'delete', onClick: handleDelete },
    { label: 'secret', iconName: 'key', condition: (row) => !row.hasSecret, iconClassName: 'icon-no-secret', onClick: handleOpenSecret },
    { label: 'hasSecret', iconName: 'key', condition: (row) => row.hasSecret, iconClassName: 'icon-has-secret', onClick: handleOpenSecret },
    { label: 'link', iconName: 'link', condition: (row) => !row.linked, iconClassName: 'icon-link-account', onClick: handleConnect },
    { label: 'linked', iconName: 'link', condition: (row) => row.linked, iconClassName: 'icon-link-account', onClick: handleConnect },
    { label: 'refresh', iconName: 'refresh',  iconClassName: 'icon-link-account', onClick: handleRefresh },
  ];

  const addTag = async (tag, contact) => {
    const thisContact = {...rows.find(c => c.id === contact.id)}
    await assetsAPI.addTag(tag, thisContact)
    thisContact.tags = [...thisContact.tags, tag];
    setRows([...rows.map(c => c.id === contact.id ? thisContact : c)])
  }    


  const removeTag = async (tag, contact) => {
    const thisContact = {...rows.find(c => c.id === contact.id)}
    await assetsAPI.removeTag(tag, thisContact)
    thisContact.tags = [...thisContact.tags.filter(t => t !== tag)];
    setRows([...rows.map(c => c.id === contact.id ? thisContact : c)])
  }  

  const displayValue = (value, column, row) => {
    switch (column.id) {
      case "masked":
        return value.toString().length <= 4 ? value : `${'*'.repeat(value.toString().length - 4)}${value.toString().slice(-4)}`;
      case "estimatedValue":
        const intValue = parseInt(value?.replace(/[^0-9.-]+/g, ""), 10);
        return isNaN(intValue) ? '$0.00' : intValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      case "tags":
        return <TagRow tags={value} removeTag={(tag) => removeTag(tag, row)} addTag={(tag) => addTag(tag, row)} />
      default:
        return value;
    }
  };

  return (
    <Card className="card-no-shadow dashboard">
      <TabList>
        <Tab activeTab={activeTab} label="Research" onClick={setActiveTab}>
          Wealth Insights
        </Tab>
        <Tab activeTab={activeTab} label="Goals" onClick={setActiveTab}>
          Financial Goals
        </Tab>
        <Tab activeTab={activeTab} label="Assets" onClick={setActiveTab}>
          Accounts
        </Tab>
        <Tab activeTab={activeTab} label="Budget" onClick={setActiveTab}>
          Budget
        </Tab>
        <Tab activeTab={activeTab} label="alerts" onClick={setActiveTab}>
          Alerts
        </Tab>
        <Tab activeTab={activeTab} label="property" onClick={setActiveTab}>
          Property
        </Tab>
        
      </TabList>

      <TabPanel activeTab={activeTab} label="Research">
        
        <ResearchSection />
      </TabPanel>
      <TabPanel activeTab={activeTab} label="Budget">
        
        <Budgeting />
      </TabPanel>
      
      <TabPanel activeTab={activeTab} label="Assets">
          
      {
      
      editingAsset ? (
            <HStack variant="spread">
      <Text variant="h3">Editing Asset {editingAsset.description}</Text>
    </HStack>
        ) : (

      <>
            <HStack variant="spread">
          <Text variant="h3">Assets</Text>
          <FormDialog
            buttonText="Add Asset +"
            updateFunction={handleSave}
            modalTitle="Asset"
            fields={fields}
            dataCallback={handleFormChangeCallback}
          />
        </HStack>
        <Table 
          columns={columns}
          isLoading={isLoading}
          rows={rows}
          filters={[{ id: 'tags', placeholder: 'Filter by tag' }]}
          totalRow={totalRow}
          actions={actions}
          displayValue={displayValue}
        /></>
        
    )}
        
      </TabPanel>
      <TabPanel activeTab={activeTab} label="Liabilities">
      <HStack variant="spread">
          <Text variant="h3">Liabilities</Text>
          <FormDialog
            buttonText="Add Liability +"
            updateFunction={handleSave}
            modalTitle="Liability"
            fields={fields}
            dataCallback={handleFormChangeCallback}
          />
        </HStack>
        <Table 
          columns={columns}
          isLoading={isLoading}
          rows={rows}
          filters={[{ id: 'tags', placeholder: 'Filter by tag' }]}
          totalRow={totalRow}
          actions={actions}
          displayValue={displayValue}
        />
      </TabPanel>
      <SecretDialog
        viewingSecret={viewingSecret}
        handleCloseSecret={handleCloseSecret}
        settings={settings}
        secret={secret}
        editingSecret={editingSecret}
        handleSecretChange={handleSecretChange}
        handleEditSecret={handleEditSecret}
        cancelEditSecret={cancelEditSecret}
        saveSecret={saveSecret}
      />
    </Card>  
  );
}
export default Assets