import React, { useState, useEffect } from "react";
import { Button, Card, HStack, Icon, Stack, Tab, TabList, TabPanel, Table, Text } from "@arcault-ui/shared-components";
import { signOut, getCurrentUser, fetchUserAttributes, fetchAuthSession , decodeJWT} from '@aws-amplify/auth';
import './Creds.css';
import { useSettings } from '../../context/SettingsContext';
import { everythingAPI } from "../../actions/everything";
import PlaidAccountConnect from '../PlaidAccountConnect';
import { personaAPI } from "../../actions/persona";
import ChaosAgentPanel from './ChaosAgentPanel';

const Creds = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userAttr, setUserAttr] = useState(null);
  const [activeTab, setActiveTab] = useState('Access Creds');

  const { settings, updateSettings } = useSettings();
  
  
  const [error, setError] = useState(null);
  
  const [copySuccess, setCopySuccess] = useState('');

    const deleteEverything = async () => {
    // Show confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete everything? This action cannot be undone.");

    if (!confirmDelete) {
      console.log("Delete action cancelled by user.");
      return; // Exit if user cancels
    }

    try {
      const response = await everythingAPI.delete('');
      console.log("Successfully deleted everything:", response);

      const resetResponse = await personaAPI.delete()
      console.log("Successfully  reset persona", resetResponse);
      alert("All data has been deleted successfully."); // Optional success message
    } catch (error) {
      console.error("Error deleting everything:", error);
      alert("An error occurred while deleting data."); // Optional error message
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    }, () => {
      setCopySuccess('Failed to copy!');
    });
  };
  
  
  
  
  
  /////////////
//   const [isConfigured, setIsConfigured] = useState(false);     
//       useEffect(() => {
//         if (!Amplify._config) {
//             Amplify.configure(awsconfig);
//             setIsConfigured(true);
//         } else {
//             setIsConfigured(true);
//         }
//     }, []);
//   useEffect(() => {
//         async function subscribeToUpdates() {
//             if (!isConfigured) {
//                 console.log("Waiting for Amplify to configure...");
//                 return;
//             }
//             try {
//                 const currentUser = await getCurrentUser();
//                 if (!currentUser || !userId) {
//                     console.error("No user found for subscription.");
//                     return;
//                 }

//                 console.log("Subscribing with userId:", userId, subscriptions.onUpdate);
// const session = await fetchAuthSession();
//                 const authToken = session.tokens?.idToken?.toString();
//                 const client = generateClient({
//                   auth: {
//                       type: "AMAZON_COGNITO_USER_POOLS",
//                       jwtToken: async () =>
//                         (await fetchAuthSession()).getIdToken().getJwtToken()
//                     },
//                   });
                
//                 const sub = client.graphql({ query: subscriptions.onUpdate, 
//                                             variables: {userId},
//                 }).subscribe({
//                   next: ({ data }) => {
//                     // const newTodo = data.onUpdate;
//                     console.log("Received update:", data);
//                   },
//                   error: error => {
//                     console.warn(JSON.stringify(error, null, 2))
//                     console.warn(error)
//                   },
//                 });
                
//                 return () => sub.unsubscribe(); // Cleanup on unmount
                
                
                
//             } catch (error) {
//                 console.error("Error subscribing to updates:", error);
//             }
//         }

//         if (isConfigured) {
//             subscribeToUpdates();
//         }
//     }, [isConfigured, userId]);
    
    
  /////////////
  
  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        // Get the current authenticated user's session
        const session = await fetchAuthSession();
        
        setIdToken(session.tokens.idToken.toString())
        setAccessToken(session.tokens.accessToken.toString());
        
        let userAtt = await fetchUserAttributes(session.tokens.idToken)
        setUserId(userAtt["custom:userId"])
        setUserAttr(JSON.stringify(userAtt))
        
      } catch (err) {
        console.error("Error fetching access token:", err);
        setError("Unable to fetch access token. Please log in.");
      }
    };

    fetchAccessToken();
  }, []);


  return (
    <Card className="card-dashboard">

      <TabList>
        <Tab activeTab={activeTab} label="Access Creds" onClick={setActiveTab}>Access Creds</Tab>
        <Tab activeTab={activeTab} label="Chaos Agent" onClick={setActiveTab}>Chaos Agent</Tab>
        <Tab activeTab={activeTab} label="Test" onClick={setActiveTab}>Test</Tab>
        
      </TabList>
      <TabPanel activeTab={activeTab} label="Access Creds">
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">Access Token</Text>
        {accessToken ? (
          <>
            <Text variant="access-token">{accessToken}</Text>
            <button onClick={() => copyToClipboard(accessToken)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching access token..."}
          </Text>
        )}
      </div>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">Id Token</Text>
        {idToken ? (
          <>
            <Text variant="access-token">{idToken}</Text>
            <button onClick={() => copyToClipboard(idToken)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching access token..."}
          </Text>
        )}
      </div>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">User Id</Text>
        {userId ? (
          <>
            <Text variant="access-token">{userId}</Text>
            <button onClick={() => copyToClipboard(userId)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching userId..."}
          </Text>
        )}
      </div>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">User Attributes</Text>
        {userAttr ? (
          <>
            <Text>{userAttr}</Text>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching user attributes..."}
          </Text>
        )}
      </div>
      <div>
      <h1>View Experimental Features</h1>
      <button onClick={() => updateSettings({ viewExperimental: true })} >
        View All
      </button>
      </div>
    </TabPanel>
    <TabPanel activeTab={activeTab} label="Chaos Agent">
      <ChaosAgentPanel />
    </TabPanel>
    <TabPanel activeTab={activeTab} label="Test">
      <PlaidAccountConnect assetId="123" userId="456" isConnected={false}/>
    </TabPanel>
    </Card>

  );
};

export default Creds;