import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import { Toast } from '@arcault-ui/shared-components'; // Import the abstracted toast
import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast();

const createToast = (title, status) => {
    toast({
        position: 'bottom-left',
        render: () => <Toast title={title} status={status} />,
    });
};

export const genericCall = async (method, url, payload) => {
    const removeInterceptors = setupAxiosWithCredentials();
    try {
        const data = await axiosWithCredentials[method](url, payload);
        return data;
    } finally {
        removeInterceptors();
    }
}

class BASE_API {
    
    constructor(base, id_location, human_readable_name) {
        
        this.base_url = base;
        this.id_location = id_location || 'id';
        this.human_readable_name = human_readable_name || {
            singular: 'data',
            plural: 'data'
        };
    }



    async save(payload) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.post(this.base_url, payload);
            createToast("Your " + this.human_readable_name.singular + ' has been saved.', 'success');
            return data;
        } catch (error) {
            createToast("We had an issue saving your " + this.human_readable_name.singular, 'error');
        } finally {
            removeInterceptors();
        }
    }

    async addTag(tag, entity) {
        console.log('entity', entity)
        const newEntity = {
            ...entity,
            tags: [...entity.tags, tag]
        }
        this.edit(newEntity[this.id_location], newEntity);
    }

    async removeTag(tag, entity) {
        const newEntity = {
            ...entity,
            tags: [...entity.tags.filter(t => t !== tag)]
        }
        this.edit(newEntity[this.id_location], newEntity);
    }

    async get(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = id ? 
                await axiosWithCredentials.get(this.base_url + '/' + id) : 
                await axiosWithCredentials.get(this.base_url)
                return data;
        } catch (error) {
            createToast("We had an issue loading your " + this.human_readable_name.plural, 'error');
            console.error('Error fetching data:', error);
            throw error;
        } finally {
            removeInterceptors();
        }
    }

    async edit(id, payload) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.put(this.base_url + '/' + id, payload);
            createToast("Your " + this.human_readable_name.singular + ' has been modified.', 'success');
            return data;
        } catch (error) {
            createToast("We had an issue saving your " + this.human_readable_name.singular, 'error');
        } finally {
            removeInterceptors();
        }
    }

    async delete(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.delete(this.base_url + '/' + id);
            createToast("Your " + this.human_readable_name.singular + ' has been deleted.', 'success');

            return data;
        } catch (error) {
            createToast("We had an issue deleting your " + this.human_readable_name.singular, 'error');
        } finally {
            removeInterceptors();
        }
    }
}

export default BASE_API;

