import Base, { genericCall } from './base';

const BASE_URL = '/ajourney/everything';

class Everything extends Base {
    async delete() {
        await genericCall('delete', BASE_URL);
    }
}

export const everythingAPI = new Everything(BASE_URL);
