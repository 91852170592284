import { genericCall } from "./base";
import Base from './base';

const BASE_URL = '/fo/secret';

class Secrets extends Base {
    async get(type, id) {
        return await genericCall('get', BASE_URL + '/' + type + '/' + id);
    }
    async retrieve(id) {
        return await genericCall('get', '/fo/d/' + id);
    }
    async save(payload, service) {
        return await genericCall('post', BASE_URL + '/' + service + '/' + payload.sortKey, payload);
    }
}

export const secretsAPI = new Secrets(BASE_URL);
