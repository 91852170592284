import { useState, useEffect, useRef } from 'react';
import { Button, Card, Form, HStack, Icon, SkeletonLoader, Stack, Table, Text } from "@arcault-ui/shared-components";
// import { chatAPI } from '../../actions/chat'; // Assume a backend AI service
import { TextField } from '@mui/material';
import ChartMessage from './ChartMessage';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import FeedbackControls from './FeedbackControls'
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';


const ChatLog = ({ messages, onSendFeedback }) => {
  const chatEndRef = useRef(null);
  const axiosWithCredentials = useAxiosWithCredentials();

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
  <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
    <Stack variant="start">
      {messages.map((msg, index) => {
        const isChart = msg.type === 'chart';
        const extraClass = 'chat-message-' + msg.direction;

        return (
          <div key={index} className={`chat-message ${extraClass}`}>
            <Text variant="caption">{msg.user}</Text>
            {isChart ? (
              <ChartMessage chart={msg} />
            ) : (
              <Text variant="body">{msg.content}</Text>
            )}
             {msg.direction === 'incoming' && (
              <FeedbackControls
                message={msg} // ensure you track this uniquely
                onSendFeedback={onSendFeedback}
              />
             )}
          </div>
        );
      })}
      <div ref={chatEndRef} />
    </Stack>
    </Box>
  );
};

const ChatComponent = ({ messages, sendMessage, setContextInfo, onSendFeedback , isTyping}) => {
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (!input.trim()) return;
    sendMessage(input);
    setInput('');
  };

  return (
    <Stack>
      <ChatLog messages={messages} onSendFeedback={onSendFeedback} />
      {isTyping && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <CircularProgress size={16} sx={{ mr: 1 }} />
          <Text variant="body2">Assistant is thinking...</Text>
        </Box>
      )}
      <Stack direction="row">
        <TextField value={input} onChange={(e) => setInput(e.target.value)} placeholder="Ask a question..." />
        <Button type="primary" onClick={handleSend}>Send</Button>
      </Stack>
    </Stack>
  );
};

export default ChatComponent;
