import Base, { genericCall } from './base';

const BASE_URL = 'fo/liabilities';

class Liabilities extends Base {
    async delete(row) {
        return await genericCall('delete', BASE_URL + `/fo/liabilities/${row.type}/${row.id}`);
    }
}

export const liabilitiesAPI = new Liabilities(BASE_URL);
