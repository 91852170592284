import { Text, List, ListItem } from '@arcault-ui/shared-components';

const InfoSection = ({ contextInfo }) => {
  if (!contextInfo || contextInfo.length === 0) {
    return <Text>No relevant transactions found.</Text>;
  }

  return (
    <List>
      {contextInfo.map((item, index) => (
        <ListItem key={index}>
          {item.date} - {item.description} - ${item.amount}
        </ListItem>
      ))}
    </List>
  );
};

export default InfoSection;
