import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Card, Text, Form } from '@arcault-ui/shared-components';
import { assetsAPI } from '../../actions/assets';
import {assetFields} from './Assets';
const AssetDetails = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('Details');
  const [asset, setAsset] = useState(null);

  useEffect(() => {
    const fetchAsset = async () => {
      const response = await assetsAPI.get(id);
      setAsset(response.data);
    };
    fetchAsset();
  }, [id]);

  if (!asset) {
    return <Text>Loading...</Text>;
  }

  return (
    <Card>
      <Text variant="h3">{asset.description || 'Asset Details'}</Text>

      <TabList>
        <Tab activeTab={activeTab} label="Details" onClick={() => setActiveTab('Details')}>Details</Tab>
        <Tab activeTab={activeTab} label="Transactions" onClick={() => setActiveTab('Transactions')}>Transactions</Tab>
      </TabList>

      <TabPanel activeTab={activeTab} label="Details">
        <Form fields={assetFields.assetFields} initialValues={asset} onSave={async (updatedAsset) => {
          await assetsAPI.save(updatedAsset);
          setAsset(updatedAsset);
        }} />
      </TabPanel>

      <TabPanel activeTab={activeTab} label="Transactions">
        <Text variant="body">Transaction history will go here.</Text>
      </TabPanel>
    </Card>
  );
};

export default AssetDetails;
