import { Table, TagRow } from '@arcault-ui/shared-components';
import { useState } from 'react';

const DenseTable = ({ transactions, isLoading, setTransactions }) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  // Define the table columns
  const columns = [
    { id: 'txn_date', label: 'Date', align: 'left' },
    { id: 'txn_description', label: 'Description', align: 'left' },
    { id: 'txn_amount', label: 'Amount', align: 'right' },
    { id: 'tags', label: 'Tags' },
  ];

  const handleTagAdd = async (tag, transaction) => {
    transaction.tags = [...transaction.tags, tag];
    setTransactions([...transactions.map(t => t.id === transaction.id ? transaction : t)]);
  };

  const handleTagRemove = async (tag, transaction) => {
    transaction.tags = transaction.tags.filter(t => t !== tag);
    setTransactions([...transactions.map(t => t.id === transaction.id ? transaction : t)]);
  };

  // Display formatted values
  const displayValue = (value, column, row) => {
    if (column.id === "amount") {
      return `$${parseFloat(value).toFixed(2)}`;
    }
    if (column.id === "tags") {
      return <TagRow tags={value} removeTag={(tag) => handleTagRemove(tag, row)} addTag={(tag) => handleTagAdd(tag, row)} />;
    }
    return value;
  };

  return (
    <Table 
      columns={columns}
      isLoading={isLoading}
      rows={transactions}
      displayValue={displayValue}
      onClick={(row) => setSelectedTransaction(row)}
    />
  );
};

export default DenseTable;
