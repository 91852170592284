import React, { useState, useEffect } from "react";
import { Button, ChatLog, Card, Form, HStack, Icon, SkeletonLoader, Stack, Table, Text } from "@arcault-ui/shared-components";
import useDataEventsNotification from "../../hooks/useDataEventsNotification";
import * as subscriptions from "../../graphql/subscriptions";
import { chaosAPI } from "../../actions/chaos";

const sitComs = [
    {
      "title": "Parks and Rec",
      "name": "Leslie Knope",
      "img": "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR-pNN1oE1-BYVxiOMoCts83OooQa_ZCG-AowmxDzQVkAV3w0vs2-R8240cTzb2t6dFwMvJ",
      "persona": "Leslie Knope: Female, 34 years old, no children. Deputy Director of Pawnee Parks and Recreation, dedicated to improving her town. Passionate, ambitious, and married to Ben Wyatt, a state auditor and later congressman."
    },
    {
      "title": "The Office",
      "name": "Pam Beesly",
      "img": "https://cdn.cinematerial.com/p/297x/ntkf882y/the-office-dvd-movie-cover-md.jpg?v=1456217838",
      "persona": "Pam Beesly: Female, 30 years old, no children. Receptionist at Dunder Mifflin Paper Company. Artistic, kind-hearted, and later pursues a career in art. Married to Jim Halpert, with whom she has two children."
    },
    {
      "title": "The Fresh Prince",
      "name": "Will Smith",
      "img": "https://m.media-amazon.com/images/M/MV5BYzhlMDVmNTgtZjkwOS00NGYwLTllMWEtYjY2OWNkMDliMTAyXkEyXkFqcGc@._V1_.jpg",
      "persona": "Will Smith: Male, teenager, no kids. Sent from West Philadelphia to live with wealthy relatives in Bel-Air. Charismatic and playful nature, often finds himself in humorous situations adjusting to his new lifestyle."
    },
    {
      "title": "Martin",
      "name": "Martin Payne",
      "img": "https://m.media-amazon.com/images/M/MV5BM2Q3Yjg4M2ItMjZhNS00MWU3LTk3YmQtNTVkOTI4M2M5ZWJjXkEyXkFqcGc@._V1_FMjpg_UX1000_.jpg",
      "persona": "Martin Payne: Male, early 30s, no children. A radio DJ and later a television talk show host in Detroit. Known for his loud and brash personality, often finds himself in comedic predicaments alongside his girlfriend Gina and their friends."
    },
    {
      "title": "Growing Pains",
      "name": "Jason Seaver",
      "img": "https://media.themoviedb.org/t/p/w440_and_h660_face/rXccxqJ8qa6H5SgVBvUB9LGiY9J.jpg",
      "persona": "Jason Seaver: Male, 45 years old, 3 children. Works as a psychiatrist and runs his practice from home. Married to Maggie, a TV reporter. A loving father who strives to maintain balance between his career and family life."
    },
    {
      "title": "Who's the Boss",
      "name": "Tony Micelli",
      "img": "https://media.themoviedb.org/t/p/w440_and_h660_face/uZjNwfCNi6lX3jmYAxHB1DUuqcB.jpg",
      "persona": "Tony Micelli: Male, 38, 1 child. Former pro baseball player, now a housekeeper for Angela Bower, a successful advertising exec, with whom he eventually develops a relationship. Widower, devoted father to Samantha."
    },
    {
      "title": "Family Ties",
      "name": "Steven Keaton",
      "img": "https://m.media-amazon.com/images/M/MV5BMWZjZGJkZjMtNjg2Zi00ODRjLTliYzYtMWQzNTVkOWE0MDRmXkEyXkFqcGc@._V1_.jpg",
      "persona": "Steven Keaton: Male, 48 years old, 4 children. Works at PBS, married to Elyse who is a successful architect. Hippie-turned-family man, deeply caring and values social issues, raising kids who have a mix of liberal and conservative views."
    },
    {
      "title": "Cheers",
      "name": "Diane Chambers",
      "img": "https://m.media-amazon.com/images/M/MV5BNGFjY2QwM2QtZGI3Yi00MzM3LTliOGUtZDJhNzVhMGNmN2ZjXkEyXkFqcGc@._V1_.jpg",
      "persona": "Diane Chambers: Female, 34 years old, no children. A sophisticated and intellectual waitress at Cheers. Has an on-and-off romantic relationship with Sam Malone. Aspires to be a writer and often clashes with the bar's regulars."
    },
    {
      "title": "Golden Girls",
      "name": "Dorothy Zbornak",
      "img": "https://m.media-amazon.com/images/M/MV5BOGM0ODkzNWUtYjIxNC00ZTdlLTg1MGEtNmExNjQ0YmYxZGFkXkEyXkFqcGc@._V1_FMjpg_UX1000_.jpg",
      "persona": "Dorothy Zbornak: Female, 54, 2 children. A substitute teacher who shares a house with her mother, Sophia, and two friends, Blanche and Rose. Divorced from Stan, she’s sarcastic, intelligent, and the voice of reason."
    },
    {
      "title": "custom",
    }
  ]


const ChaosAgentPanel = ({ }) => {

    const [sitCom, setSitCom] = useState(null);
    const [allUpdates, setAllUpdates] = useState([]);
    const [isPaused, setIsPaused] = useState(false);
    const [payload, setPayload] = useState(null);

    const { data, isSubscribed } = useDataEventsNotification({
        query: subscriptions.onUpdate
    });

    useEffect(() => {
        if (sitCom) {
            const data = {
                journeyId: 'estate_planning',
                persona: `Sitcom: ${sitCom.title} Character: ${sitCom.persona}`
            }
            chaosAPI.start(data);
            setPayload(data);
            setAllUpdates([{
                user: 'System',
                type: 'system',
                message: sitCom.persona,
            }])
        }
    }, [sitCom]);

    useEffect(() => {
        if (data) {
            console.log("Handling new update:", );
            let message 
            try {
                message = JSON.parse(data.onUpdate.message)


                const newLog = [...allUpdates]
                if (message?.ask) {
                  const incoming = {
                      user: 'Agent',
                      type: 'incoming',
                      message: message?.ask,
                  }
                  newLog.push(incoming)
              }
                if (message?.response) {
                    const outgoing = {
                        user: sitCom?.name,
                        type: 'outgoing',
                        message: message?.response,
                    }
                    newLog.push(outgoing)
                }

                setAllUpdates(newLog); // Add new data to existing list
            } catch (e) {
                console.error("Failed to parse message:", data.onUpdate.message);
            }
        }
    }, [data]);

    return (
    <div className="sitcom-table">
        <HStack variant="spread">
          <Text variant="h1">Chaos Agent</Text>
          <Button type="outline" onClick={() => chaosAPI.delete()}>Delete All</Button>
        </HStack>
      {sitCom && (
        <Card className="neu-card selected-card">
          <HStack>
            <Stack className="selected-card-left">
                <div style={{ paddingBottom: 12 }}>
                    <Text variant="subheading">{sitCom.title}</Text>
                    <Text variant="h2">{sitCom.name}</Text>
                </div>
              <img src={sitCom.img} />
              <Text variant="caption">Subscription Status: {isSubscribed ? "Active" : "Not Subscribed"}</Text>
            </Stack>
            <Stack className="selected-card-right" variant="stretch">
              <div className="agent-thread">
                    {allUpdates.length === 1 && (
                      <Stack variant="start">
                          <div className="chat-message chat-message-system">
                            <Text variant="body">{allUpdates[0].message}</Text>
                          </div>
                          <div className="chat-message chat-message-incoming">
                            <SkeletonLoader isText count={3} />
                          </div>
                          <div className="chat-message chat-message-outgoing">
                            <SkeletonLoader isText count={3} />
                          </div>
                      </Stack>
                    )}
                    {allUpdates.length > 1 && <ChatLog messages={allUpdates} />}
                </div>
                <div>
                    {!isPaused && <Icon name="pause" variant="accent" onClick={() => { chaosAPI.pause(payload); setIsPaused(true)}} />}
                    {isPaused && <Icon name="resume" variant="accent" onClick={() => { chaosAPI.resume(payload); setIsPaused(false)}} />}
                </div>
            </Stack>
          </HStack>
        </Card>
      )}
      {!sitCom && (
        <Table
          rows={sitComs}
          CardView={({ data }) => (
            <>
              {data.title === "custom" && (
                <Card className="neu-card selection-card selection-card-custom">
                  <Stack className="selection-card-custom-content">
                    <div style={{ paddingBottom: 12 }}>
                      <Text variant="body">Custom</Text>
                      <Text variant="subheading">Persona</Text>
                    </div>
                    <Form 
                      fields={[
                        { id: 'name', placeholder: 'Name', label: 'Name', type: 'text', fullWidth: true },
                        { id: 'persona', placeholder: 'Persona', label: 'Persona', type: 'textarea', fullWidth: true },
                      ]}
                      saveText="Play"
                      updateFunction={(data) => setSitCom({
                        ...data,
                        title: "custom",
                      })}
                    />
                  </Stack>
                </Card>
              )}
              {data.title !== "custom" && (
                <Card onClick={() => setSitCom(data)} className="neu-card selection-card">
                  <div className="veil" />
                  <Stack>
                    <div>
                      <Text variant="body">{data.title}</Text>
                      <Text variant="subheading">{data.name}</Text>
                    </div>
                    <img src={data.img} />
                    <div className="persona">
                      {data.persona}
                      <Icon name="play_circle" variant="accent" />
                    </div>
                  </Stack>
                </Card>
            )}
            </>
          )}
          defaultViewType="card"
          columns={[{ id: 'title', label: 'Title' }, {id: 'name', label: 'Name' }]}
        />
      )}
    </div>
  );
};

export default ChaosAgentPanel;
