import Base, { genericCall } from './base';

const BASE_URL = '/ajourney/chaos';

class Chaos extends Base {
    async start(payload) {    
        const data = await genericCall('post', this.base_url + '/start', payload);
        return data;
    }
    async pause(payload) {    
        const data = await genericCall('post', this.base_url + '/pause', payload);
        return data;
    }
    async resume(payload) {    
        const data = await genericCall('post', this.base_url + '/resume', payload);
        return data;
    }
    async delete(payload) {    
        const data = await genericCall('delete', this.base_url , payload);
        return data;
    }
}

export const chaosAPI = new Chaos(BASE_URL);
