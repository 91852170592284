import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  ScatterChart,
  Scatter,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
  Legend,
} from 'recharts';
import { Card, Text } from '@arcault-ui/shared-components';

const chartColors = [
  '#00C49F', '#61dafb', '#ffc658', '#FF8042',
  '#0088FE', '#8884d8', '#82ca9d',
];

const getFormatter = (type) => {
  switch (type) {
    case 'currency':
      return (val) => `$${Number(val).toLocaleString()}`;
    case 'percent':
      return (val) => `${(Number(val) * 100).toFixed(0)}%`;
    case 'integer':
      return (val) => parseInt(val, 10);
    case 'date':
      return (val) => new Date(val).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    case 'month':
      return (val) => new Date(val).toLocaleDateString('en-US', { month: 'short' });
    case 'time':
      return (val) => new Date(`1970-01-01T${val}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    default:
      return (val) => val;
  }
};

const ChartMessage = ({ chart }) => {
  if (!chart || !chart.data) return null;

  const data = typeof chart.data === 'string' ? JSON.parse(chart.data) : chart.data;
  const parsedData = data.map(d => ({
    ...d,
    [chart.yAxis]: Number(d[chart.yAxis]),
  }));
  const maxY = Math.max(...parsedData.map(d => d[chart.yAxis]));
  const paddedMaxY = Math.ceil(maxY * 1.2);

  const renderChart = () => {
    switch (chart.chartType) {
      case 'bar':
        return (
          <BarChart data={parsedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={chart.xAxis} tickFormatter={getFormatter(chart.xFormat)} />
            <YAxis domain={[0, paddedMaxY]} tickFormatter={getFormatter(chart.yFormat)} />
            <Tooltip formatter={getFormatter(chart.yFormat)} />
            <Bar dataKey={chart.yAxis}>
              {parsedData.map((_, i) => (
                <Cell key={i} fill={chartColors[i % chartColors.length]} />
              ))}
            </Bar>
          </BarChart>
        );
      case 'line':
        return (
          <LineChart data={parsedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={chart.xAxis} tickFormatter={getFormatter(chart.xFormat)} />
            <YAxis domain={[0, paddedMaxY]} tickFormatter={getFormatter(chart.yFormat)} />
            <Tooltip formatter={getFormatter(chart.yFormat)} />
            <Line type="monotone" dataKey={chart.yAxis} stroke={chartColors[0]} strokeWidth={2} dot />
          </LineChart>
        );
      case 'area':
        return (
          <AreaChart data={parsedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={chart.xAxis} tickFormatter={getFormatter(chart.xFormat)} />
            <YAxis domain={[0, paddedMaxY]} tickFormatter={getFormatter(chart.yFormat)} />
            <Tooltip formatter={getFormatter(chart.yFormat)} />
            <Area type="monotone" dataKey={chart.yAxis} stroke={chartColors[0]} fill={chartColors[1]} />
          </AreaChart>
        );
      case 'scatter':
        return (
          <ScatterChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" dataKey={chart.xAxis} name={chart.xAxis} tickFormatter={getFormatter(chart.xFormat)} />
            <YAxis type="number" dataKey={chart.yAxis} name={chart.yAxis} tickFormatter={getFormatter(chart.yFormat)} />
            <Tooltip formatter={getFormatter(chart.yFormat)} />
            <Scatter name={chart.title} data={parsedData} fill={chartColors[0]} />
          </ScatterChart>
        );
      case 'pie':
        return (
          <PieChart>
            <Tooltip formatter={getFormatter(chart.yFormat)} />
            <Legend />
            <Pie
              data={parsedData}
              dataKey={chart.yAxis}
              nameKey={chart.xAxis}
              cx="50%"
              cy="50%"
              outerRadius={100}
              label
            >
              {parsedData.map((_, i) => (
                <Cell key={i} fill={chartColors[i % chartColors.length]} />
              ))}
            </Pie>
          </PieChart>
        );
      default:
        return <Text variant="body">Unsupported chart type: {chart.chartType}</Text>;
    }
  };

  return (
    <Card style={{ padding: '1rem', marginTop: '0.5rem', background: 'white' }}>
      <Text variant="subtitle" style={{ marginBottom: '1rem' }}>{chart.title}</Text>
      <ResponsiveContainer width="100%" height={300}>
        {renderChart()}
      </ResponsiveContainer>
    </Card>
  );
};

export default ChartMessage;
