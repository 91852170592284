import React, { useEffect, useState } from 'react';

import './Dashboard.css';
import { Loading } from '@arcault-ui/shared-components';
import Dashboard from './Dashboard';
import InboxDashboard from '../inboxDashboard/InboxDashboard';
import { offersAPI } from '../../../actions/offers';
import { journeyAPI } from '../../../actions/journey';
import { assetsAPI } from '../../../actions/assets';
import { liabilitiesAPI } from '../../../actions/liabilities';
import { contactsAPI } from '../../../actions/contacts';

const DATA_STATE = 'data';
const COMM_STATE = 'comm';

const DashboardController = ({ journey, final_image, final_image_sent, professionalName, title }) => {
  const [state, setState] = useState();
  const [offers, setOffers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [requiredTeam, setRequiredTeam] = useState([]);

  const fetchAssets = async () => {
    try {
      const response = await assetsAPI.get();
      setAssets(response.data);
    } catch (error) {
      console.error("Failed to load assets:", error);
    }
  };

  const fetchLiabilities = async () => {
    try {
      const response = await liabilitiesAPI.get();
      setLiabilities(response.data);
    } catch (error) {
      console.error("Failed to load liabilities:", error);
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await contactsAPI.get();
      setContacts(response.data.map(contact => ({
        ...contact,
        name: contact.givenName + ' ' + contact.familyName,
      })));
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  const fetchOffers = async () => {
    const offerResponse = await offersAPI.get();
    setOffers(offerResponse?.data);
    console.log('offerResponse', offerResponse);
    if (offerResponse?.data?.length > 0) {
      setState(COMM_STATE);
    } else {
      setState(DATA_STATE);
    }
  };

  const fetchJourney = async () => {
    try {
      journeyAPI.modifyJourney(journey);
      const response = await journeyAPI.get();
      setRequiredDocuments(response?.data?.info?.documents);
      setRequiredTeam(response?.data?.info?.team);
    } catch (error) {
      console.error("Failed to load journey:", error);
    }
  };

  const loadAssociatedData = () => {
    fetchContacts();
    fetchLiabilities();
    fetchAssets();
  };

  useEffect(() => {
    fetchJourney();
    loadAssociatedData();
  }, []);

  useEffect(() => {
    fetchOffers();
    fetchJourney();
    loadAssociatedData();
  }, []);

  return (
    <>
      {/*!state && <Loading />*/}
      {(state === DATA_STATE) && (
        <Dashboard
          isLoading={!state}
          final_image={final_image}
          final_image_sent={final_image_sent || final_image}
          professionalName={professionalName}
          title={title}
          journey={journey}
          contacts={contacts}
          assets={assets}
          liabilities={liabilities}
          requiredDocuments={requiredDocuments}
          requiredTeam={requiredTeam}
          fetchContacts={fetchContacts}
          loadAssociatedData={loadAssociatedData}
        />
      )}
      {state === COMM_STATE && (
        <InboxDashboard
          requiredDocuments={requiredDocuments}
          offers={offers}
          final_image={final_image}
          professionalName={professionalName}
          title={title}
          journey={journey}
          requiredTeam={requiredTeam}
          contacts={contacts}
          assets={assets}
          liabilities={liabilities}
        />
      )}
    </>
  );
};

export default DashboardController;
