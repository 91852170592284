export const onUpdate = /* GraphQL */ `
  subscription onUpdate($userId: ID!) {
    onUpdate(userId: $userId) {
      id
      type
      entityId
      message
      userId
      createdAt
    }
  }
`;