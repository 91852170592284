import { Card, Vault } from "@arcault-ui/shared-components";
import useDocuments from "../../hooks/useDocuments";

const Documents = () => {
    return (
        <Card title="Documents" className="dashboard documents-dashboard card-no-shadow">
            <Vault 
                useDocuments={useDocuments}
                filters={[{ id: 'tags', placeholder: 'Filter by tag' }]}
            />
        </Card>
    );
}

export default Documents;