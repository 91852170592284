import { useState, useEffect } from 'react';
import { Card, Text, HStack, Stack } from '@arcault-ui/shared-components';
import {Typography} from '@mui/material';
import DenseTable from './DenseTable'; // New compact table component
import ChatComponent from './ChatComponent'; // Chatbot interface
import InfoSection from './InfoSection'; // Contextual information
import { useMediaQuery } from '@mui/material';
import MStack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import useDataEventsNotification from "../../hooks/useDataEventsNotification";
import * as subscriptions from "../../graphql/subscriptions";

// import { transactionsAPI } from '../../actions/transactions'; // API call for transactions

const ResearchSection = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [botResponses, setBotResponses] = useState([]);
  const [contextInfo, setContextInfo] = useState("");
  const [allUpdates, setAllUpdates] = useState([]);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const axiosWithCredentials = useAxiosWithCredentials();
  
  const { data, isSubscribed } = useDataEventsNotification({
      query: subscriptions.onUpdate
  });
  // // Load Transactions
  // useEffect(() => {
  //   const fetchTransactions = async () => {
  //     setIsLoading(true);
  //     const response = {}//await transactionsAPI.get();
  //     setTransactions(response.data || []);
  //     setIsLoading(false);
  //   };
  //   fetchTransactions();
  // }, [transactions]);
  const [isThinking, setIsThinking] = useState(false);
  const sendMessage = async (text) => {
            // Add user message to the updates 
            
            if(text === ':clear'){
              setAllUpdates([])
              return;
            }
            
            
            setIsThinking(true);
            try{
            const outgoing = {
              user: 'User',
              direction: 'outgoing',
              type: 'message',
              content: text
            };
            setAllUpdates((prev) => [...prev, outgoing]);
            setTransactions([])
            const response = await axiosWithCredentials.post(`/financial-agent/input`, {
              text
            });
            }
            finally{
              // setIsThinking(false);
            }
          }
  const sendFeedback = async (payload) => {
            const response = await axiosWithCredentials.post(`/financial-agent/chat/feedback`, {
              ...payload
            });
          }
  useEffect(() => {
        if (data) {
            setIsThinking(false);
            console.log("Handling new update:",data.onUpdate.message );
            let message 
            try {
                message = JSON.parse(data.onUpdate.message)
                const newLog = [...allUpdates]
                newLog.push(message)
              // }
              if (message?.txnsData) {
                console.log(message?.txnsData)
                setTransactions(message?.txnsData || []);
              }
                setAllUpdates(newLog); // Add new data to existing list
            } catch (e) {
                console.error("Failed to parse message:", data.onUpdate.message);
            }
        }
    }, [data]);
useEffect(() => {
  const fetchMessages = async () => {
    try {
      const response = await axiosWithCredentials.get(`/financial-agent/chat/history`);
      const data = response.data; // No need for .json() with axios
      console.log('Fetched messages:', data);
      if (data.messages) {
        setAllUpdates(data.messages);
      }
    } catch (err) {
      console.error('Failed to load chat history:', err);
    }
  };

  fetchMessages();
}, []);

  return (
    <Stack spacing={4}>
        {/* Chatbot Section */}
        {/* <MStack direction={isLargeScreen ? 'row' : 'column'} spacing={2}> */}
        
      <Card sx={{ flex: 1 }}>
        <Text variant="h3">Financial Assistant</Text>
        <ChatComponent
          messages={allUpdates}
          sendMessage={sendMessage}
          setContextInfo={setContextInfo}
          onSendFeedback={sendFeedback}
          isTyping={isThinking}
        />
        <Typography>
         <Text variant="caption" style={{ fontSize: '0.75rem', marginTop: '1rem', color: '#666' }}>
           The Financial Assistant is an AI-powered research tool designed to help you explore your financial data. It does not provide financial, investment, or legal advice, and should not be relied upon for decisions without professional input. For personalized support, we recommend speaking with a licensed financial advisor. Need help finding a financial advisor? <a href="/find-advisor" style={{ color: '#666', textDecoration: 'underline' }}>Find one here</a>.
  </Text>
  </Typography>
      </Card>
      {/* Transactions Table */}
      <Card>
        <Text variant="h3">Transactions</Text>
        <DenseTable 
          transactions={transactions} 
          isLoading={isLoading} 
          setTransactions={setTransactions} 
        />
      </Card>

      {/*</MStack> */} 
      
      {/* Info Section */}
      <Card>
        <Text variant="h3">Contextual Insights</Text>
        <InfoSection contextInfo={contextInfo} />
      </Card>
    </Stack>
  );
};

export default ResearchSection;
