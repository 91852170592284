import React, { useState, useEffect } from 'react';
import './ProposalInbox.css';

import { 
  Avatar, 
  Card, 
  HStack,
  Icon,
  Stack,
  Text,
  Table,
  ChatPanel,
  TabList, Tab, TabPanel,
} from '@arcault-ui/shared-components';
import { agreementRFP } from '../../../actions/agreement';

const InboxCard = ({ data, onClick }) => (
  <Card onClick={() => onClick(data.id)} className="inbox-card neu-card" key={`proposal-${data.id}`}>
    <HStack>
      <Avatar user={{ avatarUrl: data?.avatarUrl }} />
      <Stack gap="0" className="proposal-card-info-stack">
        <Text variant="caption">
          {(data?.tags || []).join(', ')}
        </Text>
        <Text variant="subheading">{data?.givenName} {data?.familyName}</Text>
      </Stack>
    </HStack>
  </Card>
)

const InboxDetail = ({ activeData, setActiveData }) => {
  const [activeTab, setActiveTab] = useState('Agreement');
  const [agreement, setAgreement] = useState(null);

  useEffect(() => {
    agreementRFP.get().then((data) => {
      setAgreement(data?.data[0]);
    })
  }, []);

  return (
    <Card className="inbox-full-card neu-card" key={`proposal-${activeData.id}`}>
      <HStack className="inbox-detail-header">
        <Text variant="h2">
          <div onClick={() => setActiveData(null)}>
            <Icon name="arrow_back_ios" />
          </div> 
        </Text>
        <Text variant="h2">{activeData?.name || `${activeData?.firstName} ${activeData?.lastName}`}</Text>
      </HStack>
      <Stack className="tabbed-area">
        <TabList>
          <Tab activeTab={activeTab} label="Agreement" onClick={setActiveTab}>
            Agreement
          </Tab>
          <Tab activeTab={activeTab} label="Chat" onClick={setActiveTab}>
            Chat
          </Tab>
        </TabList>
        <TabPanel activeTab={activeTab} label="Agreement">
          {agreement && (
            <Stack>
              <div>
                <Text variant="body">Agreed: {new Date(agreement.created).toLocaleDateString()}</Text>
              </div>
              <div>
                <Text variant="h3">Summary</Text>
                <Text variant="body">{agreement.summary}</Text>
              </div>
              <div>
                <Text variant="h3">Scope of Work</Text>
                <Text variant="body">{agreement.scopeOfWork}</Text>
              </div>
              <div>
                <Text variant="h3">Pricing</Text>
                <Text variant="body">{agreement.pricing}</Text>
              </div>
            </Stack>
          )}
        </TabPanel>
        <TabPanel className="chat-tab" activeTab={activeTab} label="Chat">
          <ChatPanel />
        </TabPanel>
      </Stack>
    </Card>
  )
}

const Inbox = ({ contacts }) => {
  const [activeData, setActiveData] = useState(null);


  const sortedContacts = contacts.sort((a, b) => {
    const aIsProfessional = a.tags?.includes('professional');
    const bIsProfessional = b.tags?.includes('professional');
    if (aIsProfessional && !bIsProfessional) return -1;
    if (!aIsProfessional && bIsProfessional) return 1;
    return 0;
  });

  return (
    <>
      <div key="proposal-list" className={`proposal-inbox proposal-inbox-${contacts && contacts?.length === 3 ? 'complete' : 'incomplete'}`}>
        <div key="header" className="card-header" style={{ display: 'flex'}}>
          <HStack variant="start">
            <Text variant="h2">Your Team</Text>
          </HStack>
        </div>
        {activeData && <InboxDetail activeData={activeData} setActiveData={setActiveData} />}
        {!activeData && (
          <Table 
            defaultViewType="card" 
            rows={sortedContacts}
            columns={[
              { id: 'firstName', label: 'First Name' },
              { id: 'lastName', label: 'Last Name' },
              { id: 'tags', label: 'Tags'}
            ]}
            onClick={(id) => {
              console.log('clicker', id)
              setActiveData(contacts.find(c => c.id === id))
            }}
            CardView={InboxCard}
          />
        )}
      </div>
    </>
  )
}

export default Inbox;