import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Stack, Text } from '@arcault-ui/shared-components';
import { useNavigate } from 'react-router-dom';
import { profileAPI } from '../../actions/profile';

const JourneyOption = ({ title, icon, location, active, recommended }) => {
    const navigate = useNavigate();

    return (
        <Card
            className={`neu-card journey-choice ${
                active ? 'journey-choice-active' : 'journey-choice-inactive'
            } ${recommended ? 'journey-choice-recommended' : ''}`}
            title={title}
            footer={
                active ? (
                    <Button
                        type={recommended ? 'accent' : 'outline'}
                        onClick={() => navigate('/' + location)}
                    >
                        Let's Go
                    </Button>
                ) : (
                    <Text variant="body-large">Coming soon!</Text>
                )
            }
        >
            <Stack variant="center">
                <div className="journey-choice-icon">
                    <img src={`/${icon}_icon.png`} alt={`${title} Icon`} />
                </div>
            </Stack>
        </Card>
    );
};

const JourneySelector = () => {
    const [firstName, setFirstName] = useState('');
    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(false);
    const scrollRef = useRef();

    useEffect(() => {
        profileAPI.get().then((profile) => {
            setFirstName(profile.data.firstName);
        });
    }, []);

    useEffect(() => {
        const checkScroll = () => {
            const el = scrollRef.current;
            if (!el) return;
            setShowLeft(el.scrollLeft > 0);
            setShowRight(el.scrollLeft + el.clientWidth < el.scrollWidth);
        };

        const el = scrollRef.current;
        if (el) {
            el.addEventListener('scroll', checkScroll);
            checkScroll(); // initial check
        }

        return () => el?.removeEventListener('scroll', checkScroll);
    }, []);

    const scrollBy = (offset) => {
        scrollRef.current?.scrollBy({ left: offset, behavior: 'smooth' });
    };

    return (
        <div className="main-dashboard-card">
            <Text variant="h2">
                {firstName ? `Welcome ${firstName}!` : 'Welcome'}
            </Text>
            <Text variant="h3">How do you want to build your future today?</Text>
            <Text variant="body">
                Arcault connects you to innovative tools and professional guidance
                so you can build comprehensive life plans that secure your future,
                protect your family and define your legacy. <b>Where should we start?</b>
            </Text>

            <div className="journey-scroll-wrapper">
                {showLeft && (
                    <button className="scroll-arrow left" onClick={() => scrollBy(-300)}>{'‹'}</button>
                )}
                <div className="choices-scroll-container" ref={scrollRef}>
                    <div className="choices-row">
                        <JourneyOption title="Personal Finance" icon="budgeting" location="assets" active recommended  />
                        <JourneyOption title="Estate Planning" icon="estate_planning" location="estate" active />
                        <JourneyOption title="P&C Insurance" icon="insurance" location="insurance" />
                        <JourneyOption title="Taxes" icon="taxes" location="taxes" />
                        <JourneyOption title="Real Estate" icon="property" location="real_estate" />
                        <JourneyOption title="Health & Wellness" icon="wellness2" location="health" />
                        <JourneyOption title="Education & Development" icon="education" location="health" />
                        <JourneyOption title="Vacation & Events" icon="travel" location="health" />
                        <JourneyOption title="Eldercare Planning" icon="elder" location="health" />
                        
                        
                    </div>
                </div>
                {showRight && (
                    <button className="scroll-arrow right" onClick={() => scrollBy(300)}>{'›'}</button>
                )}
            </div>
        </div>
    );
};

export default JourneySelector;
