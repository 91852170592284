import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { Link as LinkIcon, CheckCircle, XCircle } from "lucide-react";
import { Card, CardContent, Button, Tooltip,IconButton } from '@mui/material';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

const PlaidAccountConnect = ({ assetId, userId, isConnected, onConnectionChange }) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [loading, setLoading] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [connected, setConnected] = useState(isConnected);

  useEffect(() => {
    setConnected(isConnected);
  }, [isConnected]);

  // Fetch link token from the backend
  const fetchLinkToken = async () => {
    setLoading(true);
    try {
      const response = await axiosWithCredentials.post('/plaid/api/create_link_token', { userId });
      setLinkToken(response.data.link_token);
    } catch (error) {
      console.error("Error fetching Plaid link token:", error);
    }
    setLoading(false);
  };

  // Handle successful Plaid connection
  const onSuccess = async (publicToken) => {
    setLoading(true);
    try {
      const response = await axiosWithCredentials.post('/plaid/api/set_access_token', { public_token: publicToken });
      if (response.data.access_token) {
        setConnected(true);
        onConnectionChange(assetId, true);
      }
    } catch (error) {
      console.error("Error setting access token:", error);
    }
    setLoading(false);
  };

  // Initialize Plaid Link at the top level (only when linkToken is available)
  const { open, ready } = usePlaidLink(
    linkToken
      ? {
          token: linkToken,
          onSuccess,
          onExit: () => setLoading(false),
        }
      : { token: '' } // Avoid passing undefined/null to `usePlaidLink`
  );

  // Handle opening Plaid Link UI
  const handleConnect = async () => {
    if (!linkToken) {
      await fetchLinkToken();
      open();
    } else {
      open(); // Open Plaid UI if linkToken is already available
    }
  };

  return (
    <Tooltip title={connected ? "Connected" : "Connect Account"}>
      <IconButton onClick={handleConnect} disabled={loading}>
        {connected ? (
          <CheckCircle className="text-green-500" size={20} />
        ) : (
          <LinkIcon className="text-blue-500" size={20} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default PlaidAccountConnect;
