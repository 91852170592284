import React, { useState } from 'react';
import { Box, Stack, IconButton, TextField, Button, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const FeedbackControls = ({ message, onSendFeedback,
initialFeedback = null,
  initialComment = '',
  initialSubmitted = false }) => {
  const [feedback, setFeedback] = useState(message.feedback?.feedback || null); // 'up' | 'down'
  const [comment, setComment] = useState(message.feedback?.comment || '');
  const [submitted, setSubmitted] = useState(message.feedback || false);

  const handleSubmit = () => {
    onSendFeedback({
      timestamp: message.timestamp,
      rating: feedback,
      comment: comment.trim() || null,
    });
    setSubmitted(true);
  };

  if (submitted) {
    return <Typography variant="caption">Thanks for the feedback!</Typography>;
  }

  return (
    <Box mt={0.5} sx={{ opacity: 0.6 }}>
  {!feedback ? (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <IconButton
        onClick={() => setFeedback('up')}
        size="small"
        sx={{ p: 0.5, color: 'text.secondary' }}
      >
        <ThumbUpIcon fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => setFeedback('down')}
        size="small"
        sx={{ p: 0.5, color: 'text.secondary' }}
      >
        <ThumbDownIcon fontSize="small" />
      </IconButton>
    </Stack>
  ) : (
    <Stack spacing={0.5} mt={0.5}>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Optional comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        fullWidth
        sx={{ backgroundColor: 'background.paper' }}
      />
      <Button
        variant="text"
        size="small"
        onClick={handleSubmit}
        sx={{ alignSelf: 'flex-end' }}
      >
        Submit
      </Button>
    </Stack>
  )}
</Box>
  );
};

export default FeedbackControls;
