import Base, { genericCall } from './base';

const BASE_URL = '/ajourney/datagent';

class DataAgent extends Base {
    modifyJourney(journey) {
        this.base_url = BASE_URL + '/' + journey;
    }

    async skip(journey, payload) {
        await genericCall('post', BASE_URL + '/skipstep/' + journey, payload);
//         https://api.dev.arcault.com/ajourney/datagent/skipstep/estate_planning
    }

}

export const dataAgentAPI = new DataAgent(BASE_URL + '/welcome');
