import { genericCall } from "./base";
import Base from './base';

const BASE_URL = '/ajourney/persona';

class Persona extends Base {
    async delete() {
        genericCall('put', BASE_URL + '/reset', {});
    }
}

export const personaAPI = new Persona(BASE_URL);
