import React, {useState, useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, HStack, Stack, Text } from  '@arcault-ui/shared-components';
import ProposalInbox from './ProposalInbox'
import Inbox from './Inbox'
import DocumentsViewer from '../dashboard/DocumentsViewer';

import '../dashboard/Dashboard.css';
import EstateValueCard from '../dashboard/EstateValue';

const Dashboard = ({ assets, liabilities, contacts, requiredDocuments, title, journey, final_image, professionalName, offers }) => {
  const [accepted, setAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (offers) {
      console.log('offers', offers)
      offers.forEach(offer => {
        if (offer.status === 'ACCEPTED') {
          setAccepted(true);
        }
      });
      setIsLoading(false);
    }
  }, [offers]);
    
  const navigate = useNavigate();
  return (
    <Card className="card-dashboard">
      <HStack>
        <Stack>
          <Text variant="h1" className="dashboard-title">{title}</Text>
          {!accepted && !isLoading && <ProposalInbox offers={offers} setAccepted={setAccepted} />}
          {accepted && !isLoading && <Inbox contacts={contacts} />}
        </Stack>
        <Stack className="inbox-dashboard-right">
          <EstateValueCard assets={assets} liabilities={liabilities} navigate={navigate} />
          <DocumentsViewer hasPro={accepted} journey={journey} requiredDocuments={requiredDocuments} navigate={navigate}/>
        </Stack>
      </HStack>
    </Card>
  );
}

export default Dashboard;
