import React, { useState } from 'react';
import {
  Box,
  HStack,
  VStack,
  Select,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Divider,
} from '@chakra-ui/react';

const mockBudgetData = [
  {
    id: 'food',
    label: 'Food',
    budget: 800,
    actual: 620,
    breakdown: [
      { label: 'Groceries', amount: 350 },
      { label: 'Dining Out', amount: 220 },
      { label: 'Coffee Shops', amount: 50 },
    ],
  },
  {
    id: 'transport',
    label: 'Transport',
    budget: 300,
    actual: 280,
    breakdown: [
      { label: 'Gas', amount: 150 },
      { label: 'Uber/Lyft', amount: 130 },
    ],
  },
  {
    id: 'utilities',
    label: 'Utilities',
    budget: 200,
    actual: 190,
    breakdown: [
      { label: 'Electric', amount: 90 },
      { label: 'Water', amount: 50 },
      { label: 'Internet', amount: 50 },
    ],
  },
];

const BudgetingTab = () => {
  const [selectedMonth, setSelectedMonth] = useState('2025-03');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [draftBudget, setDraftBudget] = useState({});

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setSelectedCategory(null);
  };

  const handleBudgetClick = (category) => {
    setEditingId(category.id);
    setSelectedCategory(category);
    setDraftBudget((prev) => ({
      ...prev,
      [category.id]: category.budget,
    }));
  };

  const handleBudgetSave = (category) => {
    const newValue = draftBudget[category.id];
    category.budget = newValue; // 👈 Replace with save function in real app
    setEditingId(null);
  };

  return (
    <HStack align="start" spacing={8}>
      {/* Left: Table */}
      <Box flex="2">
        <HStack justify="space-between" mb={4}>
          <Text fontSize="xl" fontWeight="bold">
            Budget for{' '}
            {new Date(selectedMonth).toLocaleDateString('en-US', {
              month: 'long',
              year: 'numeric',
            })}
          </Text>
          <Select value={selectedMonth} onChange={handleMonthChange} width="fit-content">
            <option value="2025-01">January 2025</option>
            <option value="2025-02">February 2025</option>
            <option value="2025-03">March 2025</option>
            <option value="2025-04">April 2025</option>
          </Select>
        </HStack>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th isNumeric>Budget</Th>
              <Th isNumeric>Actual</Th>
              <Th isNumeric>Remaining</Th>
            </Tr>
          </Thead>
          <Tbody>
            {mockBudgetData.map((category) => {
              const isEditing = editingId === category.id;
              const remaining = (draftBudget[category.id] ?? category.budget) - category.actual;

              return (
                <Tr key={category.id}>
                  <Td>{category.label}</Td>

                  <Td isNumeric onClick={() => handleBudgetClick(category)}>
                    {isEditing ? (
                      <Input
                        size="sm"
                        type="number"
                        value={draftBudget[category.id] ?? ''}
                        onChange={(e) =>
                          setDraftBudget((prev) => ({
                            ...prev,
                            [category.id]: parseFloat(e.target.value),
                          }))
                        }
                        onBlur={() => handleBudgetSave(category)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            handleBudgetSave(category);
                          }
                        }}
                        autoFocus
                        width="80px"
                      />
                    ) : (
                      `$${category.budget}`
                    )}
                  </Td>

                  <Td isNumeric>${category.actual}</Td>
                  <Td isNumeric color={remaining < 0 ? 'red.500' : 'green.600'}>
                    ${remaining}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      {/* Right: Drill-In Breakdown */}
      {selectedCategory && (
        <Box flex="1" borderWidth="1px" borderRadius="xl" p={4} bg="gray.50">
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {selectedCategory.label} Breakdown
          </Text>
          <Divider mb={2} />
          <VStack align="stretch" spacing={2}>
            {selectedCategory.breakdown.map((item, idx) => (
              <HStack key={idx} justify="space-between">
                <Text>{item.label}</Text>
                <Text>${item.amount}</Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      )}
    </HStack>
  );
};

export default BudgetingTab;
