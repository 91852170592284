import React, { useState, useEffect } from 'react';

import { FormDialog, HStack, TagRow, Text, Table, Avatar, Card } from '@arcault-ui/shared-components';
import ContactCard from './ContactCard';
import { contactsAPI } from '../../actions/contacts';

import './contacts.css';

export const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [editableContact, setEditableContact] = useState(null);

  useEffect(() => {
    loadData();
  }, []);


  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
    { id: 'tags', label: 'Tags' },
  ];

  const rows = contacts.map((contact) => ({
    id: contact?.id || '',
    name: `${contact?.givenName} ${contact?.familyName}`,
    phone: contact?.phone || '',
    email: contact?.email || '',
    tags: contact?.tags,
    type: contact?.type || '',
  }));

  const actions = [
    {
      label: 'edit',
      iconName: 'edit',
      onClick: (row) => initiateEditContact(row),
    },
    {
      label: 'delete',
      iconName: 'delete',
      onClick: (row) => deleteContact(row.id),
    },
  ];

  const loadData = async () => {
    try {
      const response = await contactsAPI.get();
      setIsLoading(false);
      setContacts(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (editableContact) setOpenDialog(true)
  }, [editableContact]);


  const addContact = async (newContact) => {
    try {
      const response = await contactsAPI.save(newContact);
      setContacts((prev) => [...prev, response.data]);
      setOpenDialog(false);
    } catch (err) {
      console.error(err);
    }
  }

  const initiateEditContact = (contact) => {
    const thisContact = contacts.find(c => c.id === contact.id);
    setEditableContact({ data: thisContact })
  }

  const updateContact = async (modifiedContact) => {
    try {
      const response = await contactsAPI.edit(modifiedContact.id, modifiedContact);
      setContacts(prevContacts => {
        const updatedContacts = (prevContacts || []).map(contact =>
          contact.id === modifiedContact.id ? { ...contact, ...response.data } : contact
        );
        return updatedContacts;
      });
      setOpenDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteContact = async (id) => {
    console.log('contact', id);
    const thisContact = contacts.find(c => c.id === id);
    console.log('contact', thisContact);

    try {
      await contactsAPI.delete('undefined/' + id);
      setContacts(prev => prev.filter(c => c.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const addTag = async (tag, contact) => {
    const thisContact = {...contacts.find(c => c.id === contact.id)}
    await contactsAPI.addTag(tag, thisContact)
    thisContact.tags = [...thisContact.tags, tag];
    setContacts([...contacts.map(c => c.id === contact.id ? thisContact : c)])
  }    


  const removeTag = async (tag, contact) => {
    const thisContact = {...contacts.find(c => c.id === contact.id)}
    await contactsAPI.removeTag(tag, thisContact)
    thisContact.tags = [...thisContact.tags.filter(t => t !== tag)];
    setContacts([...contacts.map(c => c.id === contact.id ? thisContact : c)])
  }  

  return (
    <>
      <Card className="card-no-shadow dashboard">
        <HStack variant="spread">
          <Text variant="h2">Contacts</Text>
          <FormDialog buttonText="Add Contact +" 
            modalTitle="Contact"
            isOpen={openDialog}
            onClose={() => setOpenDialog(false)}
            fields={[
              { id: "givenName", label: "Given Name" },
              { id: "familyName", label: "Family Name" },
              { id: "email", label: "Email", type: 'email' },
              { id: "phone", label: "Phone", type: 'phone'},
              { id: "address", label: "Address", fullWidth: true },
              { id: "birthday", label: "Birthday", type: 'date' },
            ]}
            initialState={editableContact}
            updateFunction={editableContact ? updateContact : addContact}
          />
        </HStack>
        <Table
          CardView={(props) => <ContactCard {...props} removeTag={removeTag} addTag={addTag} editContact={initiateEditContact} deleteContact={deleteContact}/>}
          isLoading={isLoading}
          filters={[{ id: 'tags', placeholder: 'Filter by tag' }]}
          columns={columns}
          rows={rows}
          actions={actions}
          displayValue={(value, column, row) => {
            if (column.id === 'name') {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar user={{ name: value }} />
                  <span style={{ paddingLeft: 12 }}>{value}</span>
                </div>
              );
            }
            if (column.id === 'tags') {
              return <TagRow tags={value} removeTag={(tag) => removeTag(tag, row)} addTag={(tag) => addTag(tag, row)} />
            }
            return value;
          }}
        />
      </Card>
    </>
  );
}

export default Contacts;