import {useState, useEffect} from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';

function Secrets({onView, onDelete, onDownloadAll }) {
    const axiosWithCredentials = useAxiosWithCredentials();
    const [secrets, setSecrets] = useState([
    // Initial secrets data
  ]);


const loadItems = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get(`/info/secret/secret`);
      
      console.log(JSON.stringify(response))
      setSecrets(response.data);
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

    useEffect(() => {
      loadItems();
    }, []); 

  const handleViewSecret = (id) => {
    // Logic to handle viewing a secret
  };

  const handleDeleteSecret = (id) => {
    // Logic to delete a secret
    // Update the state to reflect the change
  };

  const handleDownloadAllSecrets = async () => {
    try {
    const response = await axiosWithCredentials.get(`/fo/download/allsecrets`, {
      responseType: "blob", // Ensures the response is treated as binary data
    });

    // Check if the browser supports URL.createObjectURL
    if (window.URL && window.URL.createObjectURL) {
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary download URL
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = "decrypted-secrets.pdf"; // File name
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Browser does not support downloading files via Blob.");
    }
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
  };


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          startIcon={<DownloadIcon />}
          variant="contained"
          onClick={handleDownloadAllSecrets}
        >
          Download All
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Secret Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {secrets.map((secret) => (
            <TableRow key={secret.id}>
              <TableCell>{secret.name}</TableCell>
              <TableCell>{secret.description}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => onView(secret.id)}><VisibilityIcon /></IconButton>
                <IconButton onClick={() => onDelete(secret.id)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
export default Secrets