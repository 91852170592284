import React, { useState, useEffect, useRef } from 'react';
import { Card, Icon, Button, HStack, SkeletonLoader, Stack, Text } from '@arcault-ui/shared-components';
import NextStep from '../NextStep';


import { rfpAPI } from '../../../actions/rfp'
import { dataAgentAPI } from '../../../actions/dataAgent';
import RFPEditor from './RFPModal';

const capitalizeFirstLetter = (val) => {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const DashboardNextStep = ({ final_image, final_image_sent, title, professionalName, journey, dashboard, priority, setProgress, setStage, loadAssociatedData }) => {
  const [nextStep, setNextStep] = useState();
  const [draftRFPOpen, setDraftRFPOpen] = useState(false);
  const [rfp, setRFP] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStep, setIsLoadingStep] = useState(false);

  const FIND_ATTORNEY_LEAD_IN = `Thanks for letting us get to know you. Time to reap the rewards. Take just 2 minutes to connect with our network of ${professionalName}`;
  const ATTORNEY_BUTTON_TEXT = 'Find Your Professional';
  const ATTORNEY_BUTTON_HELP_TEXT = `${capitalizeFirstLetter(professionalName)} ready to send you proposals. Non-binding. Non-identifying. And we\'ve already drafted your request.`;
  const WAITING_FOR_ATTORNEY_LEAD_IN = `We've sent your request to our network. Congratulations for taking this step.`
  const WAITING_FOR_ATTORNEY_TEXT = `${capitalizeFirstLetter(professionalName)} are reviewing your request. You\'ll receive proposals soon.`;  

    dataAgentAPI.modifyJourney(journey);

    const loaderRef = useRef({});
    const getUserJourney = async () => {
      try {
        setIsLoadingStep(true);
        const nextData = await dataAgentAPI.get();
        setNextStep(nextData.data);
        setProgress(nextData.data?.progress); //  * 100)
        setStage(nextData?.data?.stepId)
        setIsLoadingStep(false);        
      } catch (error) {
        console.error("Failed to load user journey:", error);
      }
    }

      useEffect(() => {


        const ACTIVE_RFP = true;
        const getRFP = async () => {
          if (ACTIVE_RFP) {
            setIsLoading(true);
            const savedData = await rfpAPI.get();
            if (savedData.data) {
              const savedRFPData = Array.isArray(savedData.data) ? savedData.data[0] : savedData.data;
              setRFP(savedRFPData);
            } else {
              const data = await rfpAPI.draft(journey);
              setRFP(data.data);
            }
            setIsLoading(false);
          }
        }
   
        if (!loaderRef?.loaded) {
          getUserJourney();
          getRFP();
          loaderRef.loaded = true;
        }
        
      }, [setProgress, loaderRef]);

      const saveData = async (propertyValue) => {
        const payload = {
          ...nextStep,
          propertyValue
        };

        const savedData = await dataAgentAPI.save(payload);
        if (setProgress) setProgress(savedData.data?.progress); // * 100)
        loadAssociatedData();
        setNextStep(savedData.data);

        return savedData;
      }

      const isStageComplete = nextStep?.stepId === 'stage_complete'
      const rfpIsPublished = rfp.status === 'PUBLISHED';

      return (
        <Stack variant="end">
          <Card 
            className={`${!isStageComplete ? 'neu-card-low' : 'card-light-border card-no-shadow'} dashboard-next-step ${dashboard ? priority ? 'priority-card-dashbord' : 'dashboard-card' : ' '}`} 
            title={dashboard ? title : (nextStep?.stepId === 'stage_complete' ? <span>Congratulations! <Icon name="celebration" /> </span> : 'Next Step')}
          >

            {nextStep && nextStep?.stepId !== 'stage_complete' && (
              <NextStep
                journey={journey}
                nextStep={nextStep}
                setNextStep={setNextStep}
                saveData={saveData}
                getUserJourney={getUserJourney}
              />
            )}
            {(nextStep && nextStep?.stepId === 'stage_complete') && (
              <div id={`step-complete`} className="step-container">
                <RFPEditor 
                  initialRFP={rfp}
                  isLoading={isLoading}
                  isOpen={draftRFPOpen}
                  setRFPSent={() => rfp.status = 'PUBLISHED'}
                  handleClose={() => setDraftRFPOpen(false)}
                />
                <Stack variant="center" className="dashboard-find-attorney dashboard-next-step-inner">
                  {!rfpIsPublished && <><Text variant="body-large"> {FIND_ATTORNEY_LEAD_IN}</Text>
                  <Card className="find-attorney-button neu-card" onClick={() => setDraftRFPOpen(true)}>
                    <HStack variant="spread">
                      <img src={`/${final_image}.png`} alt="Find Attorney" className="dashboard-find-attorney-image" />
                        <Stack className="find-attorney-button-text-box" variant="center">
                          <Text variant="caption">{ATTORNEY_BUTTON_HELP_TEXT}</Text>
                          <Button type="accent">{ATTORNEY_BUTTON_TEXT}</Button>
                        </Stack>
                    </HStack>
                  </Card></>}
                  {rfpIsPublished && <><Text variant="body-large"> {WAITING_FOR_ATTORNEY_LEAD_IN}</Text>
                    <Card className="find-attorney-button card-no-shadow" onClick={() => setDraftRFPOpen(true)}>
                      <HStack variant="spread">
                        <img src={`/${final_image_sent}.png`} alt="Find Attorney" className="dashboard-find-attorney-image" />
                          <Stack className="find-attorney-button-text-box" variant="center">
                            <Text variant="body">{WAITING_FOR_ATTORNEY_TEXT}</Text>
                          </Stack>
                      </HStack>
                    </Card>
                  </>}
                </Stack>
              </div>
            )}
          </Card>
        </Stack>
      );
    };

    
    export default DashboardNextStep;
