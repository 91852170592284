import { useEffect, useState, useRef } from "react";
import { generateClient } from "aws-amplify/api";
import { fetchAuthSession, fetchUserAttributes, getCurrentUser } from "@aws-amplify/auth";

const useDataEventsNotification = ({ query }) => {
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const subscriptionRef = useRef(null); // Store active subscription

  useEffect(() => {

    let isMounted = true; // Prevent state updates after unmount

    async function subscribe() {
      try {
        if (subscriptionRef.current) {
          console.log("Already subscribed, skipping...");
          return;
        }
      
        const session = await fetchAuthSession()
        const sessionAttributes = await fetchUserAttributes(session.tokens.idToken)
        
        const client = generateClient({
          auth: {
            type: "AMAZON_COGNITO_USER_POOLS",
            jwtToken: async () => session.getIdToken().getJwtToken(),
          },
        });

        setUserId(sessionAttributes["custom:userId"])

        const sub = client.graphql({ query, variables: { userId } }).subscribe({
          next: ({ data }) => {
            if (isMounted) {
              console.log("Received update:", data);
              setData(data); // Update state
            }
          },
          error: (err) => {
            console.warn("Subscription error:", err);
            setError(err);
          },
        });

        subscriptionRef.current = sub; // Store active subscription
        setIsSubscribed(true);
      } catch (err) {
        console.error("Subscription error:", err);
        setError(err);
      }
    }

    subscribe();

    return () => {
      isMounted = false; // Prevent updates after unmount

      if (subscriptionRef.current) {
        console.log("Unsubscribing...");
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null; // Clear reference
      }

      setIsSubscribed(false);
    };
  }, [query, userId]); // Re-run only if query or userId changes

  return { data, error, isSubscribed };
};

export default useDataEventsNotification;