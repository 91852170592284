import { useState } from 'react';
import { secretsAPI } from '../../../actions/secrets';

export const useSecrets = ({ service, saveSecretCallback }) => {
  const [secret, setSecret] = useState({});
  const [viewingSecret, setViewingSecret] = useState(false);
  const [editingSecret, setEditingSecret] = useState(false);
  const [formData, setFormData] = useState({});

  const handleOpenSecret = async (item) => {
    setFormData(item);
    if (item.hasSecret) {
      const response = await secretsAPI.get(service, item.sortKey);
      console.log('response', response);
      setSecret({ ...response.data, plaintext: response.data.ciphertextBlob.data });
      setEditingSecret(false);
    } else {
      setEditingSecret(true);
      setSecret({});
    }
    setViewingSecret(true);
  };

  const handleCloseSecret = () => {
    setViewingSecret(false);
    setEditingSecret(false);
  };

  const handleSecretChange = (event) => {
    setSecret({ ...secret, [event.target.name]: event.target.value });
  };

  const handleEditSecret = async () => {
    try {
      const response = await secretsAPI.retrieve(secret.sortKey);
      console.log('secret', response)
      setSecret({ ...secret, plaintext: response.data.plaintext || response.data.ciphertextBlob, infoMessage: response.data.plaintext ? "" : "You used a private key" });
    } catch (error) {
      console.error("Error loading files:", error);
    }
    setEditingSecret(true);
  };

  const cancelEditSecret = () => {
    setViewingSecret(false);
    setEditingSecret(false);
  };

  const saveSecret = async () => {
    try {
      console.log('save secret', secret);
      let response
      if (formData.secretCreatedOn) {
        response = await secretsAPI.edit(`${service}/${formData.sortKey}`, secret)
      } else {
        response = await secretsAPI.save({ ...secret, ...formData}, service);
      }
      if (saveSecretCallback) await saveSecretCallback();
      if (response.status !== 200 && response.status !== 201) {
        console.error(formData.secretCreatedOn ? 'Secret Update failed' : 'Secret Create failed');
      }
    } catch (error) {
      console.error(formData.secretCreatedOn ? 'Error during Secret Update' : 'Error during Secret Create', error);
    }
    setEditingSecret(false);
    setViewingSecret(false);
  };

  return {
    secret,
    secretData: formData,
    viewingSecret,
    editingSecret,
    handleOpenSecret,
    handleCloseSecret,
    handleSecretChange,
    handleEditSecret,
    cancelEditSecret,
    saveSecret,
  };
};
