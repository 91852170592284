import React from 'react';

import { Avatar, Card, HStack, Icon, Stack, TagRow, Text } from '@arcault-ui/shared-components';

export const ContactCard = ({ data, onClick, addTag, removeTag, editContact, deleteContact }) => {
    return (
        <Card 
            data-testId="contact-card"
            className={`neu-card-low customer-card ${data.status === 'OPEN' ? 'dot' : ''}`}
            footer={(
                <HStack variant="spread" className="customer-card-footer">
                    <div>
                        <Icon onClick={() => {}} name="call" />
                        <Icon onClick={() => {}} name="email" />
                    </div>
                    <div>
                        <Icon variant="secondary" onClick={() => editContact(data)} name="edit" /> 
                        <Icon variant="secondary" onClick={() => deleteContact(data.id)} name="delete" />
                    </div>
                </HStack>
            )}>
            <Stack className="customer-card-inner arcault-stack-low-gap" variant="start">
                <Avatar user={{ name: data.name, avatarUrl: data?.avatarUrl }} />
                <div className="customer-card-title">
                    <Text variant="h3">{data.name}</Text>
                </div>
                <div>
                    <TagRow addTag={(tag) => addTag(tag, data)} removeTag={(tag) => removeTag(tag, data)} tags={data.tags} />
                </div>
            </Stack>
        </Card>
    )
}

export default ContactCard;