import Base, { genericCall } from './base';

const BASE_URL = 'fo/contacts';

class Contacts extends Base {
    async edit(id, payload) {
        return await genericCall('put', BASE_URL + '/' + `${payload.type}/${payload.id}`, payload)
    }

    /*async addTag(contact, tag) {
        return await genericCall('post', `${BASE_URL}/${contact.type}/${contact.id}/tag/${tag}`);
    }

    async removeTag(contact, tag) {
        return await genericCall('delete', `${BASE_URL}/${contact.type}/${contact.id}/tag/${tag}`);
    }*/
}

export const contactsAPI = new Contacts(BASE_URL, 'id', { singular: 'contact', plural: 'contacts' });
