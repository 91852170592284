import React, { useState, useEffect } from 'react';
import { Button, Text, FormInput, HStack, Modal, Loading } from '@arcault-ui/shared-components';

import { rfpAPI } from '../../../actions/rfp'

import './RFPModal.css';

const ACTIVE_RFP = true;
const RFPEditor = ({ setRFPSent, isLoading, isOpen, handleClose, initialRFP }) => {
  const [rfp, setRFP] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!rfp.id) {
        setRFP(initialRFP);
    }
  }, [initialRFP]);

  const handleOnChange = (id, e) => {
    setRFP({ ...rfp, [id]: e.target.value });
  }

  const saveRFP = async () => {
    if (ACTIVE_RFP) {
      setIsSaving(true);
      const data = await rfpAPI.save(rfp);
      setIsSaving(false);
    }
  }

  const publishRFP = async () => {
    if (ACTIVE_RFP) {
      setIsSending(true);
      await saveRFP();
      await rfpAPI.publish(rfp.id);
      setRFPSent(true);
      handleClose();
      setIsSending(false);
    }
  }

  const formatData = (value, type) => {
    if (type && type.toLowerCase() === 'currency') {
      return `$${Number(value).toLocaleString('en-US')}`;
    }
    return value;
  }

  return (
    <Modal className="rfp-edit-modal" onClose={handleClose} isOpen={isOpen} title="Create Your Proposal" footer={(
      <HStack variant="end">
        <Button onClick={() => saveRFP()} type="outline">Save Draft</Button>
        <Button onClick={() => publishRFP()} type="primary">Send</Button>
      </HStack>
    )} >
      {isLoading && <Loading label="Loading..." />}
      {isSaving && <Loading label="Saving..." />}
      {isSending && <Loading label="Sending..." />}
      <Text variant="caption">Arcault protects your data. As part of this message, attorneys will receive ONLY the non-identifiable data about you and your family displayed below. This is necessary to bring you the best proposals from those attorneys. We advise you NOT to add identifying data to the editable fields below.</Text>

      <div className="lead-meta-data">
        {(rfp?.profileData || []).map((metaData) => (
          <HStack variant="stretch" key={metaData.id}>
            <Text variant="body"><b>{metaData.label}</b></Text>
            <Text variant="body">{formatData(metaData.value, metaData.type)}</Text>
          </HStack>
        ))}
      </div>
      <FormInput className="rfp-modal-summary" value={rfp.summary} type="textarea" label="Summary" onChange={(e) => handleOnChange('summary', e)} />
      <FormInput className="rfp-modal-notes" value={rfp.notes} type="textarea" label="Notes" onChange={(e) => handleOnChange('notes', e)} />
    </Modal>
  )
}

export default RFPEditor;