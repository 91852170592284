import React, { useEffect, useState } from 'react';

import { Card, Text, Button, HStack, Stack } from '@arcault-ui/shared-components';
import FormDialog from '../../assets/infoAssets/FormDialog';
import './EstateValue.css'; 

import { assetFields, handleDynamicFields } from '../../assets/Assets';
import { assetsAPI } from '../../../actions/assets';
import { liabilitiesAPI } from '../../../actions/liabilities';

const EstateValueCard = ({ navigate, assets, liabilities }) => {
  const [estateValue, setEstateValue] = useState(null);
  const [largestAssets, setLargestAssets] = useState([]);
  const [largestLiabilities, setLargestLiabilities] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isAsset, setIsAsset] = useState(true);
  const [fields, setFields] = useState([...assetFields.commonFields]);

  const formattedValue = (value) => {
    let guaranteedValue = parseInt(value) || 0;
    if (guaranteedValue >= 1000000) {
        return `$${(guaranteedValue / 1000000).toFixed(1)} million`;
    } else if (guaranteedValue >= 10000) {
        return `$${(guaranteedValue / 1000).toFixed(1)}k`;
    } else {
        return `$${guaranteedValue}`;
    }
  };
  const hasValue = Boolean(estateValue);

  useEffect(() => {
    handleDynamicFields(formData, setFields, isAsset);
  }, [formData]);


  useEffect(() => {
    if (assets && liabilities) {
        let totalAssets = 0;
        assets.forEach(asset => {
            const value = parseFloat(asset?.estimatedValue);
            if (!isNaN(value)) {
                totalAssets += value;
            }
        });
        const largestAssetsList = assets
            .sort((a, b) => b.estimatedValue - a.estimatedValue)
            .slice(0, 3);

        // Safely calculate total liabilities
    const totalLiabilities = liabilities.reduce((sum, liability) => {
            const value = parseFloat(liability?.estimatedValue);
            return !isNaN(value) ? sum + value : sum;
        }, 0);
        const largestLiabilitiesList = liabilities
            .sort((a, b) => b.estimatedValue - a.estimatedValue)
            .slice(0, 3);

        const estimatedValue = totalAssets - totalLiabilities;
        
        setEstateValue(estimatedValue);
        setLargestAssets(largestAssetsList.map(j => j.assetType).join(', '));
        setLargestLiabilities(largestLiabilitiesList.map(j => j.assetType).join(', '));
    }
  }, [assets, liabilities]);

    const handleClose = () => {
        setFormOpen(false);
        setFormData({});
    };

    const handleSave = async () => {
        console.log('formData', formData);
        try {
          let response
          if (formData.service === 'Asset') {
            response = await assetsAPI.post(formData);
          } else {
            response = await liabilitiesAPI.post(formData); 
          }
          if (response.status === 200 || response.status === 201) console.log('Assets post successful');
        } catch (error) {
          console.error('Error during Update', error);
        }

        handleClose();
    };
      const handleFormChange = (e, id) => {
        setFormData({ ...formData, [id]: e.target.value });
      };

  return (
    <Card className="estate-value-card your-vault-card" footer={(
        <HStack variant="end">
            <Button type="outline" onClick={() => navigate('/assets')}>View all</Button>
        </HStack>
    )}>
        <FormDialog
          open={formOpen}
          handleClose={handleClose}
          editMode={editMode}
          dialogTitle={isAsset ? "Asset" : "Liability"}
          fields={fields}
          formData={formData}
          service={isAsset ? 'assets' : 'liabilities'}
          handleFormChange={handleFormChange}
          handleSave={handleSave}
        />
        <Stack variant="center">
            <div className="estate-value-inner">
                <Text variant="h2">Estate Value</Text>
                <Text variant="h1" className={`value ${hasValue ? 'has-value' : 'no-value'}`}>
                    <span data-testid="estate-value">{formattedValue(estateValue)}</span>
                </Text>
                    {assets && <div className="description">
                        <Text variant="body">
                            Based on <span data-testid="assets-total">{assets.length}</span> asset{assets.length > 1 || assets.length === 0 ? 's' : ''} and <span data-testid="liabilities-total">{liabilities.length}</span> liabilit{liabilities.length > 1 || liabilities.length === 0 ? 'ies' : 'y'}
                            {/*((largestAssets && largestAssets.length > 0) || (largestLiabilities && largestLiabilities.length > 0)) && `, including your 
                            ${largestAssets}
                            ${liabilities.length > 0 && assets.length > 0 && ', and '}
                            ${largestLiabilities}. `*/}
                          </Text>
                        <Text variant="body">
                            <Button type="link" onClick={() => {
                              setIsAsset(true);
                              setFormOpen(true);
                            }}>Add Asset</Button>
                            <span style={{ paddingLeft: 9 }}>|</span>
                            <Button type="link" onClick={() => {
                              setIsAsset(false);
                              setFormOpen(true);
                            }}>Add Liability</Button> 

                        </Text>
                    </div>}
            </div>
        </Stack>
    </Card>
  );
};

export default EstateValueCard;

/* assetType
: 
"Car"
description
: 
"Toyota Car"
estimatedValue
: 
"1500"
make
: 
"Fit"
model
: 
"Fit"
year
: 
"2015"*/

/*
address
: 
"36 Rue des Vignes"
assetType
: 
"House"
baths
: 
"2"
bedrooms
: 
"3"
description
: 
"Primary Residence"
estimatedValue
: 
"100000"
lot
: 
"3"
size
: 
"3600"*/

/* descriptoin items, estimatedValue */