import React from 'react';
import DashboardController from '../common/dashboard/DashboardController';

const EstateDashboard = () => {
  return (
    <DashboardController
      journey="estate_planning"
      final_image="estate_attorney_final"
      final_image_sent="estate_attorney_reading"
      professionalName="attorneys"
      title="Estate Planning"
    />
  );
};

export default EstateDashboard;
