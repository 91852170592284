import { useState, useEffect, useMemo } from 'react';
import BasicInfo from './BasicInfo';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials'
import { Grid, Container, Button, Box } from '@mui/material';

import { updateUserAttributes } from 'aws-amplify/auth';
import {useSettings} from '../../context/SettingsContext'
export default function Basics({user}) {
  
const {basicInfo = {} , updateBasicInfo} = useSettings();

// Function to update user attributes
const updateCognito = async (firstName, lastName) => {
  try {
    const result = await updateUserAttributes({userAttributes: {
      'given_name': firstName,
      'family_name': lastName,
      // You can add other attributes here
    }});
    console.log('User attributes updated successfully', result);
    // Handle success (e.g., show a success message)
  } catch (error) {
    console.error('Error updating user attributes:', error);
    // Handle error (e.g., show an error message)
  }
};

  const handleSave = async (event) => {
    event.preventDefault();
        try{
        const response =  await axiosWithCredentials.put(
            '/users/profile',
            formData);
        setFormDataChanged(false)
        }
        catch(err){
          console.log(err)
        }
  };
  const axiosWithCredentials = useAxiosWithCredentials()
  const [formDataChanged, setFormDataChanged,] = useState(false);
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState(null);
  useEffect(() => {
    // This effect runs once on mount to load data
    loadData();
  }, []);

  const loadData = async () => {
    try{
      setFormData(basicInfo);
      setInitialFormData(basicInfo);
        }
        catch(err){
          console.log(err)
    }
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormDataChanged(true)
  };
  const handleReset = () => {
    console.log("resetting change")
    setFormData({ ...initialFormData });
    setFormDataChanged(false)
  };


  
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
      <form>
        {/* Your BasicInfo component */}
        {/* Assuming BasicInfo takes formData and updates it */}
        <BasicInfo formData={formData} setInitialFormData={setInitialFormData} setFormData={setFormData} handleInputChange={handleInputChange}/>

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={!formDataChanged}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={!formDataChanged}
            onClick={handleReset}
          >
            Cancel
          </Button>
        </Box>
      </form>
      </Grid>
    </Grid>
  );

}


