import { useState, useEffect } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth'

import { Icon, Card, Text, Avatar, Button, HStack, Loading, Tooltip, Table } from '@arcault-ui/shared-components';
import { Box, Menu, MenuItem } from '@mui/material'; 

import useAxiosWithCredentials from '../../../hooks/useAxiosWithCredentials';

import './DigitalVault.css';

const DigitalVault = ({ label, category, useDocuments }) => {
  
const {
  documents,
  deleteDocument,
  handleUploadClick,
  UploadMechanism,
  fileShareStatus,
  setFileShareStatus,
  isLoading,
  dragOver,
  DropArea,
} = useDocuments({ category });

  const [sharedIds, setSharedIds] = useState([]);
  
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const axiosWithCredentials = useAxiosWithCredentials();

  const [userId, setUserId] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchUserId = async () => {
        try {
            const authSession = await fetchAuthSession()
            const  tokens = authSession.tokens
            const id = tokens?.idToken
            const payload = id?.payload
            
            console.log("PAYLOAD: ", payload)
            const userId = payload['custom:userId'];
            setUserId(userId);
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }
    };

    fetchUserId();
    loadFiles();
  }, []);

  useEffect(() => {
    if (contacts && sharedIds) {
      console.log('contacts', contacts, sharedIds);
      const mappedContacts = sharedIds.map((id) => {
        const contact = contacts.find((c) => c.userId === id);
        return contact ? { 
          ...contact, 
          name: contact.firstName + ' ' + contact.lastName,
          id
        } : null;
      });
      setSharedUsers(mappedContacts);
    }
  }, [contacts, sharedIds]);

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = ''; // You can set a default filename here if needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Call this function when the download URL is set
  useEffect(() => {
    if (downloadUrl) {
      downloadFile(downloadUrl);
    }
  }, [downloadUrl]);

  const handleDownload = async (fileKey) => {
    setDownloadUrl(null);
    try {
      const response = await axiosWithCredentials.post('/files/download', { fileKey });
      setDownloadUrl(response.data.url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const loadFiles = async () => {
    try {
      const contactsResponse = await axiosWithCredentials.get('/fo/contacts');
      setContacts(contactsResponse.data.map((contact) => ({
        ...contact,
        name: contact.firstName + ' ' + contact.lastName,
      })));
      
    } catch (error) {
      console.error('Error loading files:', error);
    }
  };

  const handleShare = async (contact) => {
    if (!sharedUsers.includes(contact.userId)) {
      setSharedIds((prevSharedIds) => [...prevSharedIds, contact.userId]);
        await axiosWithCredentials.put(`/files/sharewith/${category}/.meta`, 
      [{userId: contact.userId, label: contact.firstName}]);
    }
  };

  const handleUnshare = async (contact) => {
    try {
      const response = await axiosWithCredentials.put(`/files/unshare/${category}/.meta`, 
      [contact.userId]);
      
      setSharedIds((prevSharedIds) => prevSharedIds.filter((su) => su.userId !== contact.userId));
    }
    catch(e) {
      console.log("Unable to unshare user");
    }
  };

  const toggleFileShare = async (fileId) => {
    try {
      if (fileShareStatus[fileId]) {
        // Unshare file
        const response = await axiosWithCredentials.put(`/files/unshare/${category}/${fileId}`, sharedUsers);
      } else {
        // Share file
        const response = await axiosWithCredentials.put(`/files/sharewith/${category}/${fileId}`, sharedUsers);
      }
      setFileShareStatus((prevState) => ({ ...prevState, [fileId]: !prevState[fileId] }));
    } catch (error) {
      console.error('Error toggling file share status', error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleContextMenu = (event, user) => {
    event.preventDefault();
    setSelectedUser(user);
    setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
    setSelectedUser(null);
  };

  const handleDelete = async (fileId) => {
    await deleteDocument(fileId, category);
  }
  
  const columns = [
    { id: 'fileName', label: 'Document Name' },
    { id: 'uploadDate', label: 'Uploaded On', align: 'left' },
    { id: 'owner', label: 'Uploaded By', align: 'left' },
  ];

  const rows = documents.filter((doc) => doc.fileName !== '.meta').map((doc) => ({
    ...doc,
    uploadDate: new Date(doc.uploadDate).toLocaleDateString('en-US'),
  }));

  const actions = [
    {
      label: 'delete',
      iconName: 'delete',
      onClick: (row) => handleDelete(row.sortKey),
      condition: (row) => row.userId === userId,
    },
    {
      label: 'download',
      iconName: 'download',
      onClick: (row) => handleDownload(row.s3Key),
    },
    {
      label: 'share',
      iconName: 'share', 
      iconClassName: 'file-shared',
      onClick: (row) => toggleFileShare(row.sortKey),
      condition: (row) => fileShareStatus[row.fileId],
    },
    {
      label: 'share',
      iconName: 'share', 
      iconClassName: 'file-not-shared',
      onClick: (row) => toggleFileShare(row.sortKey),
      condition: (row) => !fileShareStatus[row.fileId],
    },
  ];

  // (fileShareStatus[fileId]) 

  let dropDownText = 'Drop files here to upload';
  if (dragOver) dropDownText = 'Drop to upload';
  if (uploading) dropDownText = 'Uploading file';

  const DocCard = ({ data, onClick }) => {
  
    const [name, type] = data.fileName.split('.');
    
    return (
      <Card className="doc-card" footer={(
        <HStack variant="spread">
          <Icon onClick={(row) => handleDelete(row.sortKey)} name="delete" />
          <Icon onClick={(row) => handleDownload(row.s3Key)} name="download" />
          {fileShareStatus[data.fileId] && <Icon onClick={(row) => toggleFileShare(row.sortKey)} name="share'" />}
          {!fileShareStatus[data.fileId] && <Icon onClick={(row) => toggleFileShare(row.sortKey)} name="share'" />}
        </HStack>
      )}>
        <div className="doc-icon">
          <Text variant="caption">{type}</Text>
          <Icon name="draft" />
        </div>
        <div className="doc-label">
          <Text variant="body">{name}</Text>
        </div>
      </Card>
    )
  }


  return (
    <div style={{ backgroundColor: 'white', padding: 16 }}>
      <div className="digital-vault">
        <HStack variant="spread">
      <div>
        <Text variant="h4">
            {label}
        </Text>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text variant="subheading">
          Digital Vault
        </Text>
        <div style={{ paddingLeft: 8 }}>
        <Box display="flex" alignItems="center">
        <div max={4}>
          {sharedUsers?.map((user) => (
            <Tooltip label={user?.name} key={user?.id}>
              <Avatar user={user} onContextMenu={(event) => handleContextMenu(event, user)}/>
            </Tooltip>
          ))}
        </div>
        <div aria-label="share" onClick={handleMenuOpen}><Icon name="share" /></div>
      </Box>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            {contacts?.filter((contact) => (contact.email)).map((contact) => (
              <MenuItem
                key={contact.email}
                onClick={() => {
                  handleShare(contact);
                  handleMenuClose();
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Avatar user={contact} />
                  <div style={{ paddingLeft: 16 }}>
                    <Text variant="body-text" style={{ fontWeight: 'bold' }}><b>{contact?.name}</b></Text>
                    <Text variant="body-text">{contact.email}</Text>
                  </div>
                </div>
              </MenuItem>
            ))}
          </Menu>
          <Menu
            open={contextMenu !== null}
            onClose={handleContextMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
          >
            <MenuItem onClick={() => {
              handleUnshare(selectedUser);
              handleContextMenuClose();
            }}>
              Remove
            </MenuItem>
          </Menu>
          </div>
          </div>
      </div>
      <Button type="primary" onClick={handleUploadClick}>Add File +</Button>
    </HStack>

    <Table
      isLoading={isLoading && documents.length === 0} 
      columns={columns}
      rows={rows}
      onClick={() => {}}
      actions={actions}
      CardView={DocCard}
      defaultViewType="card"
    />
    {!(isLoading && documents.length === 0) && (
      <DropArea>
        <Text variant="body-text">{dropDownText} {uploading && <Loading />}</Text>
        <UploadMechanism><Icon name="upload" /></UploadMechanism>
      </DropArea>
    )}
    </div>
    </div>
  );
};

export default DigitalVault;