import React, { useState, useEffect } from 'react';
import { signOut, getCurrentUser, fetchUserAttributes, fetchAuthSession } from '@aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { Avatar } from '@arcault-ui/shared-components';
import { ChakraProvider } from '@chakra-ui/react';
import { useSettings } from '../../context/SettingsContext';

const TopBarProfileIcon = ({ handleCloseUserMenu }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { settings } = useSettings();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        const session =await fetchAuthSession();
        let userAtt = await fetchUserAttributes(session.tokens.idToken)

        setUser({
          ...currentUser,
          name: userAtt.name || `${userAtt.given_name} ${userAtt.family_name}` || userAtt.email,
        });
        
      } catch (err) {
        console.log(err);
        console.log('User not authenticated');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const options = settings.viewExperimental ? [{ label: 'Profile', to: 'profile' }] : []
  options.push({ label: 'Settings', to: 'settings' })
  options.push({ label: 'Terms', to: 'terms' })
  options.push({ label: 'Privacy Policy', to: 'privacy' })
  options.push({ label: 'Logout', to: 'logout', onClick: handleSignOut })

  const handleNavigation = (to) => {
    navigate(`/${to}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in</div>;
  }

  return (
    <ChakraProvider>
      <Box sx={{ flexGrow: 0 }}>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            className="profile-icon"
            icon={
              <Avatar
                user={user}
              />
            }
          />
          <MenuList>
            {options.map((option) => (
              <MenuItem key={option.label} onClick={handleCloseUserMenu}>
                {option.to ? (
                  <MenuItem component={Link} onClick={() => handleNavigation(option.to)}>
                    {option.label}
                  </MenuItem>
                ) : (
                  <MenuItem onClick={option.onClick}>{option.label}</MenuItem>
                )}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </ChakraProvider>
  );
};

export default TopBarProfileIcon;
