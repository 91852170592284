import { axiosWithCredentials } from "../api/axios";
import Base, { genericCall } from './base';

const BASE_URL = 'files/v2';



class Files extends Base {
    async getCategory(category) {
        return await genericCall('get', BASE_URL + '/' + category);
    }

    async addTag(fileId, tag) {
        await genericCall('put',BASE_URL + `/tag/${fileId}/${tag}`);
    }

    async getUrl(fileKey) {
        const response = await axiosWithCredentials.post('/files/download', {
            fileKey,
        });
        return response?.data?.url;
    }
}

export const filesAPI = new Files(BASE_URL);
