import { useState } from 'react';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import DigitalVault from '../profile/digitalVault/DigitalVault';
import InfoAssetsTable from '../assets/infoAssets/InfoAssetsTable';

const category = "insurance"


const Assets = () => {
  
  const axiosWithCredentials = useAxiosWithCredentials()
  
  const [documents, setDocuments] = useState([]);
  const [assets, setAssets] = useState([]);

  
  const fields = [
    { id: 'tax_year', label: 'Tax Year',column: true, form: true},
    {id: 'tax_return', label: 'Tax Return',column: true, form: true},
    {id: 'filed_by', label: 'Filed By',column: true, form: true},
    {id: 'filed_on', label: 'Filed On',column: true, form: true},
    
  ];
  


    // This is where you'll define your HealthPlan form structure
      return (
        <>
        
        <div>
      <InfoAssetsTable
        title="Tax History"
        fields={fields}
        service="taxrecords"
      />
    </div>
    
    <DigitalVault
      label="Tax Records"
      category="taxes"
    />
  
    </>
  );
}
export default Assets
