import Base, { genericCall } from './base';

const BASE_URL = 'fo/assets';

class Assets extends Base {
    async edit(id, payload) {
        return await genericCall('put', BASE_URL + `/${payload.assetType}/${payload.id}`, payload);
    } // OLD METHOD 

    async delete(row) {
        return await genericCall('delete', BASE_URL + `/fo/assets/${row.assetType}/${row.id}`);
    }
}

export const assetsAPI = new Assets(BASE_URL);